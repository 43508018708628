import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { Link } from 'react-router-dom';
// import { API } from "aws-amplify";
import "./Login.css";

import { LockOutlined, LoginOutlined } from '@ant-design/icons';
import { Input, Button, Form, Space  } from "antd";

export default function Login(props) {

  const { 
    isMobile, 
    onLoginEvent 
  } = props

  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState();
  const [form] = Form.useForm();

  
  function validateForm() {
    if(email !== undefined && password !== undefined){
      return email.length > 0 && password.length > 0;
    }
  }

  async function handleSubmit() {
    setIsLoading(true);

    try {
      await Auth.signIn(email, password);
      userHasAuthenticated(true);
      await onLoginEvent(); // Trigger event, after login, to App.js.

    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  // function getUserGata() {
  //   return API.get("od-api", `/userData`);
  // }

  const handleEmailFieldChange = (event) => {
    // console.log("event.target.id: ", event.target.value);
    setEmail(event.target.value);
  };

  const handlePasswordFieldChange = (event) => {
    // console.log("event.target.id: ", event.target.value);
    setPassword(event.target.value);
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };
  
  const tailLayout = {
    wrapperCol: { offset: 10, span: 8 },
  };


  return (
    <div className="Login">
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={handleSubmit}
        style={{ maxWidth: 600 }}
      >
        <h4 style={{ marginLeft: isMobile ? '0px' : '152px', marginBottom: '20px'}}>Log in to OD Shift</h4>
        <Form.Item style={{marginBottom: isMobile ? '16px' : '20px'}} name="Email" label="Email" rules={[{ required: true }]}>
          <Input 
            placeholder="" 
            autoFocus
            type="text"
            value={email}
            rules={[{ required: true, message: 'Please input your email address!' }]}
            onChange={handleEmailFieldChange}
          />
        </Form.Item>

        <Form.Item 
          style={{marginBottom: isMobile ? '10px' : '20px'}} 
          name="Password" 
          label="Password" 
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password
            placeholder="" 
            prefix={<LockOutlined className="site-form-item-icon" />}
            value={password}
            onChange={handlePasswordFieldChange}
            visibilityToggle={true}  // Enables the show/hide password toggle
          />
        </Form.Item>
        
          <Space >
              <Button className="primary-button button-size-large" htmlType="submit" icon={<LoginOutlined />} style={{float: isMobile ? 'left' :'right', marginLeft: isMobile ? '0px' : '150px', marginTop: isMobile ? '32px' : '16px'}} disabled={!validateForm()} loading={isLoading}>
                  Log In
              </Button>
          </Space>
          <p style={{float: isMobile ? 'left' : 'right', marginTop: isMobile ? '16px' : '16px', marginRight: '52px'}}>
              Don't have an account? 
              <Link to="/signup"> Sign Up</Link>
          </p>
          <Link to="/login/reset" style={{float: isMobile ? 'left' : 'right', marginTop: isMobile ? '-5px' : '48px', marginRight: isMobile ? '205px' : '-232px' }}><p>Forgot password?</p></Link>
      </Form>
    </div>
  );
}
