import React, { useEffect, useRef, useState } from 'react';
import { useGoogleMaps } from '../contexts/GoogleMapsContext';

export default function AddressAutoComplete({ setAddress, previousAddress }) {
  const { isLoaded, placesService } = useGoogleMaps();
  const [inputValue, setInputValue] = useState(previousAddress || '');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!isLoaded || !placesService) return;

    const service = new window.google.maps.places.AutocompleteService();

    const fetchSuggestions = (input) => {
      if (input.length < 1) {
        setSuggestions([]);
        return;
      }

      service.getPlacePredictions(
        {
          input,
          types: ['address'],
          componentRestrictions: { country: 'us' },
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            setSuggestions(predictions);
            setShowSuggestions(true);
          }
        }
      );
    };

    const handleInput = (e) => {
      const value = e.target.value;
      setInputValue(value);
      fetchSuggestions(value);
    };

    inputRef.current.addEventListener('input', handleInput);
    return () => {
      inputRef.current?.removeEventListener('input', handleInput);
    };
  }, [isLoaded, placesService]);

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.description);
    setAddress(suggestion.description);
    setSuggestions([]);
    setShowSuggestions(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div style={{ position: 'relative' }}>
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        autoComplete="off"
        placeholder="Enter an address"
        style={{
          width: '100%',
          height: '33px',
          marginTop: '-6px',
          fontSize: '14px',
          borderRadius: '8px',
          borderColor: '#A7AFBB',
          borderStyle: 'solid',
          borderWidth: '1px',
          paddingLeft: '6.4px',
          opacity: 0.8,
        }}
      />
      
      {showSuggestions && suggestions.length > 0 && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '4px',
            zIndex: 1000,
            maxHeight: '200px',
            overflowY: 'auto',
          }}
        >
          {suggestions.map((suggestion) => (
            <div
              key={suggestion.place_id}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{
                padding: '8px',
                cursor: 'pointer',
                borderBottom: '1px solid #eee',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
            >
              {suggestion.description}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}