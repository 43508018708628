import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { Link } from 'react-router-dom';
import { API } from "aws-amplify";
import { v4 as uuidv4 } from 'uuid';
import "./Signup.css";

import { InfoCircleOutlined, LockOutlined, LoginOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Popover, Input, Button, Form, Select, Space, notification, Checkbox  } from "antd";

// Generic Modal Utility
import SignUpBlockerModal from "../utilityComponents/SignUpBlockerModal";

export default function Signup(props) {

  const { 
    isMobile, 
    onLoginEvent,
    setLoadingData
  } = props
  
  const [form] = Form.useForm();

  // Scroll to top on page load
  useEffect(() => {
    console.log("should scroll to the top");
    document.querySelector('#root').scrollTo({ top: 0 });

  }, []); // Empty dependency array ensures it runs only once when the component mounts.


  const [selectedUserTypeValue, setSelectedUserDataValue] = useState(); //clinicTeamMember or individualDoctor
  const [ownerOrManager, setOwnerOrManager] = useState('');
  const [firstName, setFirstName] = useState(''); 
  const [lastName, setLastName] = useState(''); 
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');

  const nav = useNavigate();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isClinicTeamMember, setIsClinicTeamMember] = useState(false);

  // accept privacy policy
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);

  // notification modal
  const [api, contextHolder] = notification.useNotification();
  const { Option } = Select;


  const onUserTypeChange = (value) => {
    switch (value) {
      case 'clinicTeamMember':
        setSelectedUserDataValue("clinicTeamMember");
        setIsClinicTeamMember(true);
        break;
      case 'individualDoctor':
        setSelectedUserDataValue("individualDoctor");
        setIsClinicTeamMember(false);
        break;
      default:
    }
  };

  const handleOwnerOrManagerSelectChange = (value) => {
    console.log(" set owner or manager value: ", value);
    setOwnerOrManager(value);
  };

  function validateForm() {
    // validate differently if clinicTeamMember vs individualDoctor
    if(isClinicTeamMember){
      if (email !== undefined && zipCode !== undefined && password !== undefined && confirmPassword !== undefined && ownerOrManager !== undefined && acceptedPrivacyPolicy === true){
        return (
          password === confirmPassword
        );
      }
    }else{
      if (email !== undefined && zipCode !== undefined && password !== undefined && confirmPassword !== undefined && acceptedPrivacyPolicy === true){
        return (
          password === confirmPassword
        );
      }
    }
  }

  function validateConfirmationForm() {
    return confirmationCode.length === 6;
  }

  async function handleSubmit() {
    setIsLoading(true);


    try {
      const newUser = await Auth.signUp({
        username: email,
        password: password,
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit() {
    setIsLoading(true);
    setLoadingData(true); // turn on loading in App.js right away

    try {
      await Auth.confirmSignUp(email, confirmationCode);
      await Auth.signIn(email, password);

      let userData = {};

      // only get lat long from zipcode if you are a doctor..
      if(!isClinicTeamMember){
        // Get Lat Long from Zip to store in dynamoDB
        const geocoder = new window.google.maps.Geocoder();
        console.log("Geocoding zip code to retrieve lat/lng");
        const geocodingResponse = await geocoder.geocode({ address: zipCode });
        let geocodedZipResult = [];
        if (geocodingResponse.results[0]) {
            const { lat, lng } = geocodingResponse.results[0].geometry.location;
            geocodedZipResult = {
                lat: lat(), // .lat() function to get numeric latitude
                lng: lng(), // .lng() function to get numeric longitude
                formattedAddress: geocodingResponse.results[0].formatted_address,
            };
            console.log("Geocoded Zip Result:", geocodedZipResult);
            // console.log("geocodedZipResult.lat: ", geocodedZipResult.lat);
            // console.log("geocodedZipResult.lng: ", geocodedZipResult.lng);
        } else {
            console.error("No results found for the provided zip code.");
        }
        userData = {
          userType: selectedUserTypeValue,
          role: ownerOrManager,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
          zipCode: zipCode,
          lat: geocodedZipResult.lat,
          lng: geocodedZipResult.lng,
          onboardingComplete: false,
          acceptedPrivacyPolicy: acceptedPrivacyPolicy
        }
      }else{
        userData = {
          userType: selectedUserTypeValue,
          role: ownerOrManager,
          firstName: firstName,
          lastName: lastName,
          email: email,
          onboardingComplete: false,
          acceptedPrivacyPolicy: acceptedPrivacyPolicy
        }
      }

      console.log("userData: ", userData);
      createUserData(userData);
      console.log("should send data to lambda");

      // REFACTOR: should not use a wait.. should be an event.
      // After sign up, wait before refreshing the page.
      userHasAuthenticated(true);
      nav("/profile");
      // FIX THIS... Must be above 1600
      setTimeout(onLoginEvent, 1800); // Slow internet could break this... need proper event

    } catch (e) {

      // if the error is a validation error, let the user try again
      console.log("confimation code error: ", e);

      onError(e);
      setIsLoading(false);
      setLoadingData(false);
    }
  }

  const onPrivacyPolicyCheckboxChange = (e) => {
    console.log(`Accept privacy policy checked = ${e.target.checked}`);
    setAcceptedPrivacyPolicy(e.target.checked);
  };

  function createUserData(userData) {
    try {
      return API.post("od-api", "/userData", {
        body: userData,
      });
    } catch (e) {
      onError(e);
      console.log("ERROR: ", e);
    }
  }

  const handleFirstNameFieldChange = (event) => {
    // console.log("event.target.id: ", event.target.value);
    setFirstName(event.target.value);
  };

  const handleLastNameFieldChange = (event) => {
    // console.log("event.target.id: ", event.target.value);
    setLastName(event.target.value);
  };

  const handleEmailFieldChange = (event) => {
    // console.log("event.target.id: ", event.target.value);
    setEmail(event.target.value);
  };

  const handlePhoneNumberFieldChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    // console.log("formattedPhoneNumber: ", formattedPhoneNumber);
    setPhoneNumber(formattedPhoneNumber);
  };

  const handleZipCodeFieldChange = (event) => {
    // console.log("zip code: ", event);
    setZipCode(event);
  };

  const handlePasswordFieldChange = (event) => {
    // console.log("event.target.id: ", event.target.value);
    setPassword(event.target.value);
  };

  const handleConfirmPasswordFieldChange = (event) => {
    // console.log("event.target.id: ", event.target.value);
    setConfirmPassword(event.target.value);
  };

  const handleConfirmationCodeFieldChange = (event) => {
    // console.log("event.target.id: ", event.target.value);
    setConfirmationCode(event.target.value);
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  function formatPhoneNumber(value) {
    if (!value) return value;
  
    // Remove all non-numeric characters
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // Format the phone number
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) {
      return phoneNumber;
    } else if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }
  }

  function renderConfirmationForm() {
    return (
        <Form
          {...layout}
          form={form}
          autoComplete="off"
          name="control-hooks"
          onFinish={handleConfirmationSubmit}
          style={{ maxWidth: 600 }}
        >
          <h4 style={{ marginLeft: isMobile ? '0px' : '152px', marginBottom: '20px'}}>Sign up for OD Shift</h4>
          <Form.Item name="Confirmation Code" label="Confirmation Code" rules={[{ required: true }]}>
            <Input 
              placeholder="" 
              autoFocus
              value={confirmationCode}
              rules={[{ required: true, message: 'Please input your confirmation code!' }]}
              onChange={handleConfirmationCodeFieldChange}
            />
          </Form.Item>
          <p style={{marginLeft: isMobile ? '0px' : '150px'}}>Please check your email for the confirmation code.</p>
          <p style={{marginLeft: isMobile ? '0px' : '150px', marginTop: '-18px', color: 'var(--secondary-text-color)'}} >*You may need to check your spam folder</p>
          <Form.Item>
            <Space>
            <Button
              className="primary-button button-size-large"
              htmlType="submit"
              disabled={!validateConfirmationForm()}
              loading={isLoading}
              style={{ marginLeft: isMobile ? '0px' : '150px' }}
            >
              Verify
            </Button>

            </Space>
          </Form.Item>
        </Form>
    );
  }

  function renderForm() {
    return (
      <>
        <Form
          {...layout}
          form={form}
          autoComplete="off"
          name="control-hooks"
          onFinish={handleSubmit}
          style={{ maxWidth: 600 }}
          >
          <h4 style={{ marginLeft: isMobile ? '0px' : '152px', marginBottom: '20px'}}>Sign up for OD Shift</h4>
          {/* user type */}
          <Form.Item style={{marginBottom: isMobile ? '10px' : '12px'}} name="User Type" label="I'm: " rules={[{ required: true }]}>
            <Select
              size="large"
              placeholder="Who are you?"
              onChange={onUserTypeChange}
              allowClear
            >
              <Option value="clinicTeamMember">a clinic looking to hire doctors</Option>
              <Option value="individualDoctor">a doctor looking for work</Option>
            </Select>
          </Form.Item>

          {isClinicTeamMember ? 
            <Form.Item style={{marginBottom: isMobile ? '10px' : '20px'}} name="Role" label="My role is a: " rules={[{ required: true }]} >
              <Select
                  placeholder="owner or manager"
                  autoFocus
                  onChange={handleOwnerOrManagerSelectChange}
                  value={ownerOrManager}
              >
                  <Option value="owner">clinic owner</Option>
                  <Option value="manager">clinic manager</Option>
              </Select>
            </Form.Item>
            :
            <></>
           }

          <Form.Item style={{marginBottom: isMobile ? '10px' : '20px'}} name="First Name" label="First Name" rules={[{ required: true }]}>
            <Input
              size="large" 
              placeholder="" 
              type="text"
              value={firstName}
              rules={[{ required: true, message: 'Please input your first name' }]}
              onChange={handleFirstNameFieldChange}
            />
          </Form.Item>

          <Form.Item style={{marginBottom: isMobile ? '10px' : '20px'}} name="Last Name" label="Last Name" rules={[{ required: true }]}>
            <Input 
              size="large"
              placeholder="" 
              type="text"
              value={lastName}
              rules={[{ required: true, message: 'Please input your last name' }]}
              onChange={handleLastNameFieldChange}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: isMobile ? '10px' : '20px' }}
            name="Email"
            label="Email"
            rules={[
              { required: true, message: 'Please input your email address' },
              {
                validator(_, value) {
                  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                  if (!value || emailRegex.test(value)) {
                    return Promise.resolve(); // Pass validation
                  }
                  return Promise.reject(
                    new Error('Please enter a valid email address')
                  );
                },
              },
            ]}
          >
            <Input
              placeholder=""
              size="large"
              type="text"
              value={email}
              onChange={handleEmailFieldChange}
            />
          </Form.Item>

          {!isClinicTeamMember ? 
              <>
              <Form.Item
                style={{ marginBottom: isMobile ? '10px' : '20px' }}
                name="PhoneNumber"
                label="Phone Number"
                rules={[
                  { required: true, message: 'Please input your phone number!' },
                  {
                    validator(_, value) {
                      if (!value || value.replace(/\D/g, '').length >= 10) {
                        // Pass validation if at least 10 digits
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Phone number must be at least 10 digits')
                      );
                    },
                  },
                ]}
              >
                <Input
                  placeholder=""
                  size="large"
                  value={phoneNumber}
                  onChange={handlePhoneNumberFieldChange}
                />
              </Form.Item>


                <Form.Item 
                  style={{ marginBottom: isMobile ? '10px' : '20px' }} 
                  name="ZipCode" 
                  label="Zip Code" 
                  rules={[
                    { required: true, message: "Please input your zip code" },
                    {
                      pattern: /^[0-9]{5}$/, // Regular expression for 5 digits
                      message: "Zip code must be exactly 5 digits",
                    },
                  ]}>
                      <Input placeholder=""
                        size="large" 
                        type="text"
                        value={zipCode}
                        rules={[{ required: true, message: 'Please input your zip code' }]}
                        onChange={(e) => {
                          const zip = e.target.value;
                          if (/^\d*$/.test(zip)) handleZipCodeFieldChange(zip); // Only allow numeric input
                        }}
                        maxLength={5}
                      />
                </Form.Item>
              </>
            :
            <></>
           }
          <Form.Item 
            style={{marginBottom: isMobile ? '10px' : '20px'}} 
            name="Password" 
            label="Password" 
            rules={[{ required: true, message: 'Please input a password' }]}
          >
            <>
              <Input.Password 
                size="large" 
                placeholder="" 
                // prefix={<LockOutlined className="site-form-item-icon" />}
                iconRender={visible => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />} // Show/hide eye icon
                value={password}
                onChange={handlePasswordFieldChange}
              />
            </>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: isMobile ? '8px' : '10px', marginTop: isMobile ? '-6px' : '-16px', marginLeft: isMobile ? '0px' : '25%', width: '100%' }}
            name="PasswordRules"
          >
            <p 
              style={{ 
                marginBottom: '-10px', 
                marginTop: '-8px', 
                fontSize: '12px', 
                color: 'grey' }}
              >
                (Must have at least: 8 characters, 1 number, 1 special character, 1 uppercase letter, 1 lowercase letter)
            </p>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: isMobile ? '10px' : '20px' }}
            name="Confirm Password"
            label="Confirm Password"
            dependencies={['Password']} // This ensures it reacts to changes in the 'Password' field
            rules={[
              { required: true, message: 'Please confirm your password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('Password') === value) {
                    return Promise.resolve(); // Passes validation
                  }
                  return Promise.reject(
                    new Error('passwords do not match')
                  ); // Fails validation with a custom error message
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              placeholder=""
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} // Show/hide eye icon
              value={confirmPassword}
              onChange={handleConfirmPasswordFieldChange}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: isMobile ? '-4px' : '14px', marginTop: isMobile ? '0px' : '-10px', marginLeft: isMobile ? '0px' : '25%', width: '100%' }}
            name="AcceptPrivacyPolicy"
            // rules={[{ required: true, message: 'Please accept the privacy policy' }]}
          >
            <Checkbox
              checked={acceptedPrivacyPolicy}
              onChange={onPrivacyPolicyCheckboxChange}
            >
              I have read and accept the OD Shift <a href="https://odshift.com/privacyPolicy" target="_blank">privacy policy</a>
            </Checkbox>
          </Form.Item>

          <Space>
            {/* style={{marginLeft: isMobile ? '52px' :'150px'}} */} 
              <Button className="primary-button button-size-large" htmlType="submit" icon={<LoginOutlined />}  style={{float: isMobile ? 'left' :'right', marginLeft: isMobile ? '0px' : '150px', marginTop: isMobile ? '16px' : '0px'}} disabled={!validateForm()} loading={isLoading}>
                Sign Up
            </Button>
            </Space>
            <p style={{float: isMobile ? 'left' :'right', marginLeft: isMobile ? '0px' : '0px', marginRight: isMobile ? '28px' : '52px', marginTop: isMobile ? '16px' : '0px'}}>Already have an account? <Link to="/login"> Log In</Link></p>
        </Form>

        {/* Block users from signing up until launch */}
        {/* <SignUpBlockerModal></SignUpBlockerModal> */}
      </>
    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}