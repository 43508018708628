import React, { useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import BillingForm from "../components/BillingForm";
import { onError } from "../lib/errorLib";
import config from "../config";
import "./Settings.css";

export default function Settings() {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const stripePromise = loadStripe(config.STRIPE_KEY);

  // May need to comment out line 31 in index.html to allow stripe API
  // <meta http-equiv="Content-Security-Policy" content="script-src 'self' 'unsafe-eval';">

  function billUser(details) {
    return API.post("od-api", "/billing", {
      body: details,
    });
  }

  // Stripe testing CC number
  //4242 4242 4242 4242

  async function handleFormSubmit(storage, { token, error }) {
    if (error) {
      onError(error);
      return;
    }

    setIsLoading(true);

    try {
      await billUser({
        storage,
        source: token.id,
      });

      alert("Your card has been charged successfully!");
      nav("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="Settings">
      {/* <Elements
        stripe={stripePromise}
        fonts={[
          {
            cssSrc:
              "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800",
          },
        ]}
      >
        <BillingForm isLoading={isLoading} onSubmit={handleFormSubmit} />
      </Elements> */}
    </div>
  );
}
