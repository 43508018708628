import React, { useState, useRef, useEffect } from "react";
import { onError } from "../lib/errorLib";
import { v4 as uuidv4 } from 'uuid';
import { API } from "aws-amplify";
import dayjs from 'dayjs';
import "./CreateOrEditShiftModal.css";
import AddressAutoComplete from "../containers/AddressAutoComplete"
import { doctorHourTimes } from '../utilityComponents/profileComponents/doctorHourTimes';
import { useGoogleMaps } from '../contexts/GoogleMapsContext';

import { Button, Modal, Form, Input, notification, Checkbox, Select, Popover } from 'antd';

import {
    CheckCircleTwoTone, CloseCircleTwoTone, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';

function CreateClinicModal(props) {

    const { 
        handleCancel,
        userData,
        isMobile,
        onLoginEvent, // refresh page after clinic is added.
        setIsCreateClinicModalOpen,
        isCreateClinicModalOpen,
        setIsGenericModalOpen,
        setIsWelcomModalOpen
    } = props

    const [clinicName, setClinicName] = useState(""); 
    const [clinicWebsite, setClinicWebsite] = useState(""); 
    const [clinicAddress, setClinicAddress] = useState(""); 
    const [clinicPhoneNumber, setClinicPhoneNumber] = useState("");
    const [clinicEmail, setClinicEmail] = useState("");
    const [clinicDoctorHours, setClinicDoctorHours] = useState("");
    const [clinicDoctorHoursSimpleArray, setClinicDoctorHoursSimpleArray] = useState("");
    const [clinicPay, setClinicPay] = useState("");
    const [clinicNumberOfExamLanes, setClinicNumberOfExamLanes] = useState("");
    const [clinicGlassesContactsBool, setClinicGlassesContactsBool] = useState(false);
    const [clinicMedicalBool, setClinicMedicalBool] = useState(false);
    const [clinicNotes, setClinicNotes] = useState("");

    const [shiftDoctorHours_StartTime, setShiftDoctorHours_StartTime] = useState("");
    const [shiftDoctorHours_EndTime, setShiftDoctorHours_EndTime] = useState("");

    // create clinic button loading
    const [createClinicButtonLoading, setCreateClinicButtonLoading] = useState(false);

    // toast notifications
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();

    const { TextArea } = Input;
    const inputRef = useRef(null); // for address autoComplete

    function validateCreateClinic() {
        if(
            clinicName !== "" &&
            clinicWebsite !== "" &&
            clinicAddress !== "" &&
            clinicPhoneNumber !== "" &&
            clinicEmail !== "" &&
            // clinicDoctorHours !== "" && // optional
            // clinicPay !== "" && // optional
            clinicNumberOfExamLanes !== ""
            )
        {
            if(clinicGlassesContactsBool || clinicMedicalBool){
                
                // Check to make sure that not only one of the shiftDoctorHours_StartTime or shiftDoctorHours_EndTime is set.
                if (shiftDoctorHours_StartTime !== "" && shiftDoctorHours_EndTime === "" || shiftDoctorHours_EndTime !== "" && shiftDoctorHours_StartTime === ""){
                    return false;
                }

                return true;
            }
        }else{
            return false;
        }
    }

    // Initialize Google Maps API
    const { isLoaded } = useGoogleMaps();

    const checkIfUserIsAlreadyAssociatedToOrgThenCreateClinic = async () => {
        // Before creating a clinic, check the userData to see if the user is already assocaited to an org
        // if not, automatically create an org, associate them to that org.. and then create the clinic.
        console.log("userData from create clinic modal: ", userData);
        if(userData.orgId === undefined){
            console.log("userData.orgId === undefined, automatically create and join org here, then create a clinic.");
            createOrgJoinOrgThenCreateFirstClinic();

        }else{
            console.log("userData.orgId !== undefined, user is already assocaited to an org. Create a clinic");
            createClinic(userData.orgId);
        }
    };

    async function createClinic(orgId){
        setCreateClinicButtonLoading(true);
        let geocodedAddressResult = {};
        if(isLoaded){
            // Execute Geocoding here to get Lat Long and save out to dynamo for future use...
            const geocoder = new window.google.maps.Geocoder();
            const address = clinicAddress; // needs variable name address to work with google maps api..
        
            console.log("geocoding clinic address to save into dynamodb, and not need to geocode again downstream.");
            console.log("clinicAddress for geocoding: ", address);
            const geocodingResponse = await geocoder.geocode({address}); // this clinic address is not working...
            console.log("geocodingResponse: ", geocodingResponse); // full geocode object, dont need everything?
            if (geocodingResponse.results[0]) {
                const { lat, lng } = geocodingResponse.results[0].geometry.location;
                geocodedAddressResult = {
                    lat: lat(),
                    lng: lng(),
                    address,
                    clinicName: `${geocodingResponse.results[0].formatted_address.split(",")[0]}`,
                    metadata: `Clinic Info: ${address}`
                };
            }
        }

        console.log("geocoded adress results: ", geocodedAddressResult);

        // clinic data params
        const clinicDataParams = {
          orgId: orgId,
          clinicId: uuidv4(),
          clinicName: clinicName,
          clinicWebsite: clinicWebsite,
          clinicAddress: clinicAddress,
          clinicLat: geocodedAddressResult.lat,
          clinicLng: geocodedAddressResult.lng,
          clinicPhoneNumber: clinicPhoneNumber,
          clinicEmail: clinicEmail,
          clinicDoctorHours: clinicDoctorHoursSimpleArray, // send simple time string array to dynamodb
          clinicPay: clinicPay,
          clinicNumberOfExamLanes: clinicNumberOfExamLanes,
          clinicGlassesContactsBool: clinicGlassesContactsBool,
          clinicMedicalBool: clinicMedicalBool,
          clinicNotes: clinicNotes
        }
        console.log("clinicDataParams: ", clinicDataParams);
    
        // create clinic logic
        createClinic_CallApi(clinicDataParams);
    }

    function createClinic_CallApi(clinicData) {
        try {
            return API.post("od-api", "/clinicData", { //createClinicData.js
                body: clinicData,
            }).then((data) => {
            console.log("create clinic data response: ", data);
            if(data.statusCode === 200){ // check response to see if succesful or not.
                openNotification('Added Clinic Succesfully');
                const parsedBody = JSON.parse(data.body);
                console.log("parsedBody clinic creation: ", parsedBody);

                // close generic modal telling user to create a clinic
                setIsGenericModalOpen(false);
                setIsCreateClinicModalOpen(false);
                setCreateClinicButtonLoading(false);
                setIsWelcomModalOpen(false);
                handleReset();

                // the page will not resfresh until we tell it to. Pull updated userData.
                onLoginEvent();
            }else{
                openNotification('Clinic Creation Failed');
                setCreateClinicButtonLoading(false);
                setIsCreateClinicModalOpen(false);
            }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
            setCreateClinicButtonLoading(false);
        }
    };

    // If clinicTeamMember's first time through app and is not already associated to an org,
    // automatically create an org, associate them to the org, then create the clinic.
    const createOrgJoinOrgThenCreateFirstClinic = () => {
        setCreateClinicButtonLoading(true);
        const organizationData = {
            orgId: uuidv4(),
          }

        try {
            return API.post("od-api", "/organizationData", {
                body: organizationData, 
            }).then((data) => {
            console.log("create org data response: ", data);
            if(data.statusCode === 200){ // check response to see if succesful or not.
                console.log('Created Org Succesfully');
                const parsedBody = JSON.parse(data.body);

                // after creating the org, need to "join the org".
                // join by adding a key value to dynamoDB to associate org.
                const updatedUserData = {
                    userType: userData.userType,
                    orgId: parsedBody.orgId, 
                }
                joinOrgAndUpdateUserData(updatedUserData);
            }else{
                console.log('Org Creation Failed');
            }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
        }
    };

    const handleShiftDoctorHoursStartTimeChange = (value) => {
        console.log("start time value: ", value.value);
        setShiftDoctorHours_StartTime(value.value);

        // Need to ensure that the user cannot just add one, and then hit submit.. Needs to be either empty, or have both filled out.
        // if (shiftDoctorHours_EndTime !== "") {
        //     setClinicDoctorHoursSimpleArray([value.value, shiftDoctorHours_EndTime]);
        // }

        if (shiftDoctorHours_EndTime !== "") {
            // console.log("updating clinicDoctorHoursSimpleArray with value.value and shiftDoctorHours_EndTime");
            setClinicDoctorHoursSimpleArray([value.value, shiftDoctorHours_EndTime]);
        } else if (clinicDoctorHoursSimpleArray[1] !== "") {
            // console.log("updating clinicDoctorHoursSimpleArray with value.value and clinicDoctorHoursSimpleArray[1]");
            setClinicDoctorHoursSimpleArray([value.value, clinicDoctorHoursSimpleArray[1]]);
        }
    };

    const handleShiftDoctorHoursEndTimeChange = (value) => {
        console.log("end time value: ", value.value);
        setShiftDoctorHours_EndTime(value.value);

        // Need to ensure that the user cannot just add one, and then hit submit.. Needs to be either empty, or have both filled out.
        // if (shiftDoctorHours_StartTime !== "") {
        //     setClinicDoctorHoursSimpleArray([shiftDoctorHours_StartTime, value.value]);
        // }

        if (shiftDoctorHours_StartTime !== "") {
            // console.log("updating clinicDoctorHoursSimpleArray with shiftDoctorHours_StartTime and value.value");
            setClinicDoctorHoursSimpleArray([shiftDoctorHours_StartTime, value.value]);
        } else if (clinicDoctorHoursSimpleArray[0] !== "") {
            // console.log("updating clinicDoctorHoursSimpleArray with clinicDoctorHoursSimpleArray[0] and value.value");
            setClinicDoctorHoursSimpleArray([clinicDoctorHoursSimpleArray[0], value.value]);
        }
    };

    function joinOrgAndUpdateUserData(userData) {
        try {
            // PUT to /userData is update
          return API.put("od-api", "/userData", {
            body: userData,
          }).then((data) => {
            console.log("join org response: ", data);
            if(data.statusCode === 200){ // check response to see if succesful or not.
              console.log("Joined Team Succesfully");
                
              // Now create clinic after creating and joining an org.
              createClinic(data.orgId);
            }else{
              console.log("Could not Join Org, Org Does Not Exist");
            }
            // do other things
          });
        } catch (e) {
          onError(e);
          console.log("ERROR: ", e);
        }
    };

    const openNotification = (message) => {
        api.open({
            message: message,
            icon: message === "Clinic Creation Failed" ? 
            <CloseCircleTwoTone style={{ color: '#108ee9' }} /> 
            : 
            <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
        });
    };

    const resetForm = () => {
        console.log("clear modal");
        setClinicName(""); 
        setClinicWebsite(""); 
        setClinicAddress(""); 
        setClinicPhoneNumber("");
        setClinicEmail("");
        setShiftDoctorHours_StartTime("");
        setShiftDoctorHours_EndTime("");
        setClinicDoctorHours([]);
        setClinicDoctorHoursSimpleArray("");
        setClinicPay("");
        setClinicNumberOfExamLanes("");
        setClinicGlassesContactsBool(false);
        setClinicMedicalBool(false);
        setClinicNotes("");
    }

    const handleReset = () => {
        // form.resetFields();
        resetForm();
    };
        
    // For Create New Clinic
    const handleClinicNameChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicName(event.target.value);
    };

    const handleClinicWebsiteChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicWebsite(event.target.value);
    };

    const handleClinicPhoneNumberChange = (event) => {
        // console.log("event.target.id: ", event.target.value);

        const formattedPhoneNumber = formatPhoneNumber(event.target.value)
        setClinicPhoneNumber(formattedPhoneNumber);
    };

    function formatPhoneNumber(value) {
        if (!value) return value;
      
        // Remove all non-numeric characters
        const phoneNumber = value.replace(/[^\d]/g, '');
      
        // Format the phone number
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) {
          return phoneNumber;
        } else if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        } else {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
        }
      }

    const handleClinicEmailChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicEmail(event.target.value);
    };

    const closeModal = () => {
        handleCancel();
        resetForm();
    }

    const handleClinicPayChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicPay(event.target.value);
    };

    const handleNumberOfExamLanesChange = (event) => {
        console.log("event.target.id: ", event.target.value);
        setClinicNumberOfExamLanes(event.target.value);
    };

    return(
        <>
            {contextHolder}
            <Modal
                open={isCreateClinicModalOpen}
                title="Add a Clinic"
                onCancel={closeModal}
                destroyOnClose={true}  // Ensures the form component is destroyed when modal is closed - clears input fields..
                footer={ 
                    <Button key="joinClinic" className="primary-button button-size-default" loading={createClinicButtonLoading} onClick={checkIfUserIsAlreadyAssociatedToOrgThenCreateClinic} disabled={!validateCreateClinic()}>
                        Add Clinic
                    </Button>
                }
            >
            <>
                <br></br>
                <Form 
                // autoComplete="off"
                layout="vertical"
                >
                    <Form.Item name="clinic name" label="Clinic Name" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                        <Input placeholder="clinic name"
                            type="text"
                            style={{ marginTop: -12}} 
                            value={clinicName}
                            rules={[{ required: true }]}
                            onChange={handleClinicNameChange}
                            />
                    </Form.Item>
                    <Form.Item name="clinic website" label="Website" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                        <Input placeholder="website"
                            type="text"
                            style={{ marginTop: -12}} 
                            value={clinicWebsite}
                            rules={[{ required: true }]}
                            onChange={handleClinicWebsiteChange}
                            />
                    </Form.Item>

                    {/* Google Places API for address autocomplete */}
                    <Form.Item name="clinic address" label="Address" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                        <AddressAutoComplete
                            setAddress={setClinicAddress}
                            isLoaded={isLoaded}
                        />
                    </Form.Item>
                    <Form.Item name="clinic phone number" label="Phone Number" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                        <Input placeholder="phone number"
                            type="text"
                            style={{ marginTop: -12}} 
                            value={clinicPhoneNumber}
                            rules={[{ required: true }]}
                            onChange={handleClinicPhoneNumberChange}
                            />
                    </Form.Item>
                    <Form.Item name="clinic contact email" label="Contact Email (to recieve notifications)" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                        <Input placeholder="contact email"
                            type="text"
                            style={{ marginTop: -12}} 
                            value={clinicEmail}
                            rules={[{ required: true }]}
                            onChange={handleClinicEmailChange}
                            />
                    </Form.Item>
                    <Form.Item
                        // name="clinic doctor hours"
                        label={
                            <span>
                                Typical Doctor Hours (optional)
                                {isMobile ?
                                <Popover 
                                    content="Set the doctor’s usual hours here. You can always edit these hours here, or on the individual shifts on the calendar."
                                    placement="right"
                                    overlayStyle={{ width: 260 }} >
                                    <QuestionCircleOutlined style={{ color: '#1890ff', marginLeft: 8 }} />
                                </Popover>
                                :
                                <Popover 
                                    content="Set the doctor’s usual hours here. You can always edit these hours here, or on the individual shifts on the calendar."
                                    placement="right"
                                    overlayStyle={{ width: 260 }} >
                                    <QuestionCircleOutlined style={{ color: '#1890ff', marginLeft: 8 }} />
                                </Popover>
                                }
                            </span>
                        }
                        style={{ marginBottom: 4 }}
                        // rules={[{ required: true }]}
                        >
                            <>
                                {/* Start Time */}
                                <Select
                                    showSearch
                                    labelInValue
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    suffixIcon={
                                        clinicDoctorHours[0] !== "" || shiftDoctorHours_StartTime !== "" ? (
                                            <CloseCircleOutlined
                                                onClick={() => {
                                                    setShiftDoctorHours_StartTime("");
                                                    setShiftDoctorHours_EndTime("");
                                                    setClinicDoctorHours([]);
                                                    setClinicDoctorHoursSimpleArray([]);
                                                }}
                                                style={{ color: "gray", cursor: "pointer" }}
                                            />
                                        ) : null
                                    }
                                    style={{ width: 140, marginRight: '12px' }}
                                    placeholder="start time"
                                    options={doctorHourTimes}
                                    // value={shiftDoctorHours_StartTime === "" ? clinicDoctorHoursSimpleArray[0] : shiftDoctorHours_StartTime}
                                    value={shiftDoctorHours_StartTime === "" ? clinicDoctorHours[0] : shiftDoctorHours_StartTime}
                                    onChange={(value) => {
                                        handleShiftDoctorHoursStartTimeChange(value);
                                    }}
                                />
                                {/* End Time */}
                                <Select
                                    showSearch
                                    labelInValue
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    suffixIcon={
                                        clinicDoctorHours[1] !== "" || shiftDoctorHours_EndTime !== "" ? (
                                            <CloseCircleOutlined
                                                onClick={() => {
                                                    setShiftDoctorHours_StartTime("");
                                                    setShiftDoctorHours_EndTime("");
                                                    setClinicDoctorHours([]);
                                                    setClinicDoctorHoursSimpleArray([]);
                                                    }}
                                                style={{ color: "gray", cursor: "pointer" }}
                                            />
                                        ) : null
                                    }
                                    style={{ width: 140 }}
                                    placeholder="end time"
                                    options={doctorHourTimes}
                                    // value={shiftDoctorHours_EndTime === "" ? clinicDoctorHoursSimpleArray[1] : shiftDoctorHours_EndTime}
                                    value={shiftDoctorHours_EndTime === "" ? clinicDoctorHours[1] : shiftDoctorHours_EndTime}
                                    onChange={(value) => {
                                        handleShiftDoctorHoursEndTimeChange(value);
                                    }}
                                />
                            </>

                    </Form.Item>

                    <Form.Item colon={false} 
                        name="clinic pay" 
                        label={
                            <span>
                            Daily Pay ($USD) (optional)
                            {isMobile ?
                                <Popover 
                                    content={
                                        <>
                                        Set the typical daily pay rate here. You can always edit the pay here, or on the individual shifts on the calendar.
                                        <br /><br />
                                        <strong>*Note:</strong> Payment is not handled by OD Shift. Both parties are responsible for arranging payment directly.
                                        </>
                                    }
                                    placement="bottom"
                                    overlayStyle={{ width: 260 }} >
                                    <QuestionCircleOutlined style={{ color: '#1890ff', marginLeft: 8 }} />
                                </Popover>
                            :
                                <Popover 
                                    content={
                                        <>
                                        Set the usual daily pay rate here. You can always edit the pay here, or on the individual shifts on the calendar.
                                        <br /><br />
                                        <strong>*Note:</strong> Payment is not handled by OD Shift. Both parties are responsible for arranging payment directly.
                                        </>
                                    }
                                    placement="right"
                                    overlayStyle={{ width: 260 }}  >
                                    <QuestionCircleOutlined style={{ color: '#1890ff', marginLeft: 8 }} />
                                </Popover>
                            }
                            </span>
                        }    
                        style={{ marginBottom: 4}} 
                        // rules={[{ required: true }]}
                        >
                            <Input placeholder="($)"
                                style={{ marginTop: -12}} 
                                type="number"
                                value={clinicPay}
                                onChange={handleClinicPayChange}
                                onWheel={(e) => e.target.blur()}
                            />
                    </Form.Item>
                    <Form.Item colon={false} name="number of exam lanes" label="Number of Exam Lanes" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                        <Input placeholder="number of exam lanes"
                            style={{ marginTop: -12}} 
                            type="number"
                            value={clinicPay}
                            onChange={handleNumberOfExamLanesChange}
                            onWheel={(e) => e.target.blur()}
                            />
                    </Form.Item>
                        <p style={{ color: 'red', fontSize: '10px', marginTop: '8px', display: 'inline' }}>* </p><p style={{ fontSize: '14px', marginBottom: '-0px', display: 'inline' }}>Exam Type</p>
                    {isMobile ?
                    <Popover 
                        content="Specify if your clinic provides only routine exams including glasses and contacts with a standard eye health check, or medical exams (dry eye, glaucoma, macular degeneration etc.), or both."
                        placement="right"
                        overlayStyle={{ width: 260 }}>
                        <QuestionCircleOutlined style={{ color: '#1890ff', marginLeft: 8 }} />
                    </Popover>
                    :
                    <Popover 
                        content="Specify if your clinic provides only routine exams including glasses and contacts with a standard eye health check, or medical exams (dry eye, glaucoma, macular degeneration etc.), or both."
                        placement="right"
                        overlayStyle={{ width: 260 }}>
                        <QuestionCircleOutlined style={{ color: '#1890ff', marginLeft: 8 }} />
                    </Popover>
                    }
                    <Form.Item>
                        <Checkbox 
                            checked={clinicGlassesContactsBool}
                            onChange={(e) => setClinicGlassesContactsBool(e.target.checked)}> 
                            Routine (glasses and contacts)
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{marginTop: -24}}>
                        <Checkbox 
                            checked={clinicMedicalBool}
                            onChange={(e) => setClinicMedicalBool(e.target.checked)}> Medical optometry
                        </Checkbox>
                    </Form.Item>
                    <Form.Item 
                        style={{marginTop: -16}} 
                        name="clinic notes"
                        label={
                        <span>
                        Add notes about clinic: (optional)
                        {isMobile ?
                            <Popover 
                                content="Add any other notes about the clinic. (Ex: how many patients seen per hour, payment type (cash, 1099, check), additional services (punctal plugs, Optilight, specialty contacts etc)."
                                placement="right"
                                overlayStyle={{ width: 260 }} >
                                <QuestionCircleOutlined style={{ color: '#1890ff', marginLeft: 8 }} />
                            </Popover>
                        :
                            <Popover 
                                content="Add any other notes about the clinic. (Ex: how many patients seen per hour, payment type (cash, 1099, check), additional services (punctal plugs, Optilight, specialty contacts etc)."
                                placement="right"
                                overlayStyle={{ width: 260 }} >
                                <QuestionCircleOutlined style={{ color: '#1890ff', marginLeft: 8 }} />
                            </Popover>
                        }

                        </span>
                        }
                        >
                        <TextArea 
                            rows={2} 
                            placeholder="add notes" 
                            style={{ marginTop: -2}} 
                            value={clinicNotes}
                            maxLength={600} 
                            onChange={(e) => setClinicNotes(e.target.value)}
                        />
                    </Form.Item>

                </Form>
            </>
            </Modal>
        </>

    );
}

export default CreateClinicModal