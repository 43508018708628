
import React from "react"
import { Layout } from 'antd';

function Footer(props) {
    const { Footer } = Layout;

    const { isMobile } = props

    return(
        <Layout>
            <Footer style={{ 
                display: 'flex', 
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: isMobile ? 'center' : 'space-between',
                alignItems: 'center',
                textAlign: isMobile ? 'center' : 'left',
                color: 'grey',
                // backgroundColor: '#EDF0F3',
                backgroundColor: 'transparent',
                padding: isMobile ? '20px 20px' : '0px 24px',
                minHeight: '40px',
            }}>
            <div style={{ flex: isMobile ? 'unset' : 1, textAlign: 'left' }}>
                ©{new Date().getFullYear()} OD Shift LLC. All Rights Reserved.
            </div>
            {isMobile && <div style={{ height: 10 }} />} {/* Optional spacer for mobile view */}
            <div style={{ flex: isMobile ? 'unset' : 1, textAlign: isMobile ? 'center' : 'right' }}>
               info.odshift@gmail.com
            </div>
            </Footer>
        </Layout>
    );
}

export default Footer