import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, OverlayView } from '@react-google-maps/api';
import { useGoogleMaps } from '../contexts/GoogleMapsContext';

export default function GoogleMapOpto(props) {
  
  const { 
    isMobile, 
    shiftList,
    userData,
    // isGoogleMapAPILoaded
  } = props;

  const [previousShiftListAssociatedClinicList, setPreviousShiftListAssociatedClinicList] = useState(); // check to see if list has changed.
  const [shiftListAssociatedClinicList, setShiftListAssociatedClinicList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [hoveredLocation, setHoveredLocation] = useState(null);
  const [mapZoom, setMapZoom] = useState(12); // State to manage zoom level
  const mapRef = useRef(null);

  const containerStyle = {
    width: isMobile ? '90vw' : '',
    height: isMobile ? '30vh' : 'calc(100vh - 260px)',
    marginTop: '12px',
    marginLeft: isMobile ? '5vw' : '0px',
    marginRight: isMobile ? '12px' : '20px',
    marginBottom: '0px',
    borderRadius: '12px'
  };

  useEffect(() => {
    if (shiftList && shiftList.length > 0) {

      // CHECK TO SEE IF THE LIST OF CLINICS HAS CHANGED.. IF NOT DO NOT UPDATE THE GOOGLE MAP
      // EVERY TIME THE GOOGLE MAP IS UPDATED, IT RE-RENDERS AND IS AN API CALL AND COSTS MONEY..
      let shiftList_AssociatedClinicList = [];
      // get list of addresses
      // console.log("shiftList?.length: ", shiftList?.length);
      // console.log("shiftList is longer than 0");

      for (var i = 0; i < shiftList?.length; i++) {

        // Set target to check if the array already includes the clinicData object...
        const target = shiftList[i]?.clinicData;
        const includesObject = shiftList_AssociatedClinicList.some(clinic =>
          clinic?.clinicId === target?.clinicId && clinic?.clinicName === target?.clinicName
        );

        // if(!shiftList_AssociatedClinicList[i].includes(shiftList[i]?.clinicData)){
        if (!includesObject) {
          // console.log("!includesObject");

          // Need to check to make sure that this clinic has shifts that are not already scheduled...
          // TODO: Also filter this list if the open shift list is filtered..
          // don't include shift that already have accepted doctors.
          // console.log("shiftList[i]: ", shiftList[i]);
          if (shiftList[i].acceptedDoctor === undefined) {
            shiftList_AssociatedClinicList.push(shiftList[i]?.clinicData);
          }
        }
      }

      if (JSON.stringify(previousShiftListAssociatedClinicList) === JSON.stringify(shiftList_AssociatedClinicList)) {
        // console.log("previous google map shift list has not change. don't update it.");
      } else {
        // console.log("previous google map shift list has changed. update it.");
        setShiftListAssociatedClinicList(shiftList_AssociatedClinicList);
        setPreviousShiftListAssociatedClinicList(shiftList_AssociatedClinicList);
      }

    } else {
      // console.log("shiftList is empty.. set map with user lat lng");
      // console.log("userData Lat: ", userData.lat);
      // console.log("userData Lng: ", userData.lng);

      // now set the map to the lat long center...
      mapRef.current?.setCenter({
        lat: userData.lat,
        lng: userData.lng,
      });
      setMapZoom(12); // Optionally set the zoom level


    }
  }, [shiftList]);

  useEffect(() => {
    if (shiftListAssociatedClinicList && shiftListAssociatedClinicList.length > 0) {
      geocodeClinicAddresses(shiftListAssociatedClinicList);
    }
  }, [shiftListAssociatedClinicList]);

  // Format data for Google Maps
  const geocodeClinicAddresses = async (clinicAddresses) => {
    const results = [];
    // console.log("clinic addresses for re-formatting: ", clinicAddresses);
    // Passing in clinicData to set up Google Maps clinic locations
    for (const address of clinicAddresses) {
      if (address) {
        results.push({
          lat: address.clinicLat,
          lng: address.clinicLng,
          address,
          clinicName: address.clinicName,
          metadata: address.clinicAddress // add phone number? add email?
        });
      }
    }

    // console.log("location results: ", results);
    setLocations(results);

    if (results.length > 0) {
      if (results.length === 1) {
        // If only one location, set a fixed zoom of 12
        setMapZoom(12);
        mapRef.current?.setCenter(results[0]);
      } else {
        // Adjust the map bounds for multiple locations
        adjustMapBounds(results);
      }
    }else{

    }
  };

  // Helper function to adjust map bounds dynamically based on marker locations
  const adjustMapBounds = (locations) => {
    console.log("CALLING GEOCODE API... LAT LONG BOUNDS... COSTS MONEY");
    const bounds = new window.google.maps.LatLngBounds();
    locations.forEach((loc) => bounds.extend(new window.google.maps.LatLng(loc.lat, loc.lng)));
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds);
    }
  };

  // Initialize Google Maps API
  const { isLoaded } = useGoogleMaps();

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={mapZoom} // Use the dynamically set zoom level
      onLoad={(map) => (mapRef.current = map)}
    >
      {locations.map((location, index) => (
        <Marker
          key={index}
          position={{ lat: location.lat, lng: location.lng }}
          onMouseOver={() => setHoveredLocation(location)}
          onMouseOut={() => setHoveredLocation(null)}
        />
      ))}

      {hoveredLocation && (
        <OverlayView
          position={{ lat: hoveredLocation.lat, lng: hoveredLocation.lng }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div style={enhancedTooltipStyle}>
            <h6>{hoveredLocation.clinicName}</h6>
            <p style={{fontSize: '11px'}}>{hoveredLocation.metadata}</p>
          </div>
        </OverlayView>
      )}
    </GoogleMap>
  ) : <></>;
}

// Custom CSS for tooltip
const enhancedTooltipStyle = {
  backgroundColor: 'white',
  border: '1px solid #ccc',
  padding: '8px',
  paddingRight: '16px',
  paddingBottom: '0px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  fontFamily: 'Arial, sans-serif',
};