import React, { useState } from 'react';
import "./Mal.css";
import { Button, Avatar, Card, Typography, Row, Col, Form, Input, Checkbox, Upload, Popover, Divider, Modal } from 'antd';
import { PlusOutlined, CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';



const { Title, Text, Paragraph } = Typography;

export default function Mal(props) {
  const { isMobile, userData, clinicData, OpenModal_CreateAnOrg, OpenModal_JoinAnOrg, orgData, OpenModal_CreateAClinic } = props;

  // Avatar Card Component
  const AvatarNameCard = () => {
    return (
      <Card
        bordered={true}
        style={{ width: '75%', padding: '16px', textAlign: 'left', marginBottom: '32px' }}
      >
        <Row align="middle" style={{ marginTop: -16, marginBottom: -16, marginLeft: -16 }}>
          <Col xs={24} sm={6} md={4}>
            <Avatar
              size={80} 
              src={`${process.env.PUBLIC_URL}/Avatar.png`} 
              alt="User Avatar"
            />
          </Col>
        </Row>
      </Card>
    );
  };

  // Popover component
  const InfoPopover = () => {
    return (
      <Popover
        content={
          <div style={{ maxWidth: '250px' }}>
            Payments are not handled through OD Shift, they are handled independently by the clinic. 
          </div>
        }
        placement="right"
      >
        <Button
          type="text"
          shape="circle"
          icon={<InfoCircleOutlined style={{ color: '#1890ff', fontSize: '16px' }} />}
        />
      </Popover>
    );
  };

  // Add new components here

  
//   const TermsAndConditionsModal = ({ visible, onAccept, onClose }) => {
//     return (
//       <Modal
//         title="Terms and Conditions"
//         open={true}
//         onCancel={onClose}
//         footer={[
//           <Button key="back" onClick={onClose}>
//             Back
//           </Button>,
//           <Button key="submit" type="primary" onClick={onAccept}>
//             Accept
//           </Button>,
//         ]}
//         width={600}
//         bodyStyle={{ maxHeight: '70vh', overflowY: 'scroll', padding: '24px' }}
//       >
//         <Typography>
//           <Text italic>Last updated: [Date]</Text>
//           <Paragraph>Welcome to OD Shift! By using our app, you agree to these Terms and Conditions ("Terms"). Please read them carefully.</Paragraph>
  
//           <Title level={5}>1. Acceptance of Terms</Title>
//           <Paragraph>
//             By downloading, installing, or using OD Shift, you agree to be bound by these Terms. If you do not agree to these Terms, you must not use the app.
//           </Paragraph>
  
//           <Title level={5}>2. Use of the App</Title>
//           <Paragraph>
//             <Text strong>2.1.</Text> You agree to use the app only for lawful purposes and in accordance with these Terms.
//           </Paragraph>
//           <Paragraph>
//             <Text strong>2.2.</Text> You are responsible for maintaining the confidentiality of your login credentials and are fully responsible for any activities that occur under your account.
//           </Paragraph>
  
//           <Title level={5}>3. Personal Data Collection and Use</Title>
//           <Paragraph>
//             <Text strong>3.1.</Text> The app may collect personal data, including but not limited to your name, email address, and usage data, to provide and improve the service.
//           </Paragraph>
//           <Paragraph>
//             <Text strong>3.2.</Text> Your data is stored securely and will not be shared with any third parties.
//           </Paragraph>
//           <Paragraph>
//             <Text strong>3.3.</Text> We use the data to:
//             <ul>
//               <li>Provide the app's services and features</li>
//               <li>Improve and optimize the app</li>
//               <li>Respond to your requests or questions</li>
//             </ul>
//           </Paragraph>
//           <Paragraph>
//             <Text strong>3.4.</Text> You have the right to access, modify, or delete your personal data by contacting us at <Text underline>info.odshift@gmail.com</Text>.
//           </Paragraph>
  
//           <Title level={5}>4. User Content</Title>
//           <Paragraph>
//             <Text strong>4.1.</Text> You may submit content to the app, such as messages or other information. You retain ownership of any intellectual property rights in your content.
//           </Paragraph>
//           <Paragraph>
//             <Text strong>4.2.</Text> By submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use, modify, and display your content solely for the purpose of providing the app's services.
//           </Paragraph>
//           <Paragraph>
//             <Text strong>4.3.</Text> We reserve the right to remove any content that violates these Terms or is deemed inappropriate.
//           </Paragraph>
  
//           <Title level={5}>5. Intellectual Property</Title>
//           <Paragraph>
//             <Text strong>5.1.</Text> All rights, titles, and interests in the app and its content, including but not limited to software, text, images, and logos, are owned by OD Shift or its licensors.
//           </Paragraph>
//           <Paragraph>
//             <Text strong>5.2.</Text> You may not copy, modify, distribute, or create derivative works from the app without our express written consent.
//           </Paragraph>
  
//           <Title level={5}>6. Limitation of Liability</Title>
//           <Paragraph>
//             <Text strong>6.1.</Text> To the fullest extent permitted by law, OD Shift and its affiliates, officers, and employees shall not be liable for any damages arising out of your use of the app.
//           </Paragraph>
//           <Paragraph>
//             <Text strong>6.2.</Text> The app is provided "as is" without any warranties, either express or implied.
//           </Paragraph>
//           <Paragraph>
//             <Text strong>6.3.</Text> OD Shift is not responsible for mistakes made by doctors or clinics for shift scheduling, missed shifts, and is not responsible for independent payment between both parties.
//           </Paragraph>
  
//           <Title level={5}>7. Changes to the Terms</Title>
//           <Paragraph>
//             <Text strong>7.1.</Text> We reserve the right to update these Terms from time to time. Any changes will be posted within the app, and the "last updated" date will be revised.
//           </Paragraph>
//           <Paragraph>
//             <Text strong>7.2.</Text> Your continued use of the app after changes are posted constitutes your acceptance of the updated Terms.
//           </Paragraph>
  
//           <Title level={5}>8. Termination</Title>
//           <Paragraph>
//             <Text strong>8.1.</Text> We may suspend or terminate your access to the app at any time, for any reason, without notice.
//           </Paragraph>
//           <Paragraph>
//             <Text strong>8.2.</Text> Upon termination, your right to use the app will cease immediately.
//           </Paragraph>
  
//           <Title level={5}>9. Governing Law</Title>
//           <Paragraph>These Terms are governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.</Paragraph>
  
//           <Title level={5}>10. Contact Us</Title>
//           <Paragraph>If you have any questions about these Terms, please contact us at <Text underline>info.odshift@gmail.com</Text>.</Paragraph>
//         </Typography>
//       </Modal>
//     );
//   };
  
const TermsModal = ({ onClose }) => {
    return (
      <Modal
        open={true}
        onCancel={onClose}
        footer={[
          <Button key="back" className="secondary-button button-size-large" onClick={onClose}>
            Back
          </Button>,
          <Button key="accept" className="primary-button button-size-large" onClick={onClose}>
            Agree
          </Button>,
        ]}
        width={600}
        bodyStyle={{ maxHeight: '70vh', overflowY: 'auto', padding: '8px' }}
        title={<Title level={4} style={{ marginLeft: 8 }}>Terms and Conditions</Title>}
        className="terms-modal"
      >
        <div className="modal-content">
        <Paragraph className="last-updated">Last updated: [Date]</Paragraph>

        <Paragraph>
          Welcome to OD Shift! By using our app, you agree to these Terms and Conditions ("Terms"). Please read them carefully.
        </Paragraph>

        <Title level={5} className="section-title">1. Acceptance of Terms</Title>
        <Paragraph>
          By downloading, installing, or using OD Shift, you agree to be bound by these Terms. If you do not agree to these Terms, you must not use the app.
        </Paragraph>

        <Title level={5} className="section-title">2. Use of the App</Title>
        <Paragraph>
          2.1. You agree to use the app only for lawful purposes and in accordance with these Terms.
        </Paragraph>
        <Paragraph>
          2.2. You are responsible for maintaining the confidentiality of your login credentials and are fully responsible for any activities that occur under your account.
        </Paragraph>

        <Title level={5} className="section-title">3. Personal Data Collection and Use</Title>
        <Paragraph>
          3.1. The app may collect personal data, including but not limited to your name, email address, and usage data, to provide and improve the service.
        </Paragraph>
        <Paragraph>
          3.2. Your data is stored securely and will not be shared with any third parties.
        </Paragraph>
        <Paragraph>
          3.3. We use the data to:
          <ul>
            <li>Provide the app's services and features</li>
            <li>Improve and optimize the app</li>
            <li>Respond to your requests or questions</li>
          </ul>
        </Paragraph>
        <Paragraph>
          3.4. You have the right to access, modify, or delete your personal data by contacting us at info.odshift@gmail.com.
        </Paragraph>

        <Title level={5} className="section-title">4. User Content</Title>
        <Paragraph>
          4.1. You may submit content to the app, such as messages or other information. You retain ownership of any intellectual property rights in your content.
        </Paragraph>
        <Paragraph>
          4.2. By submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use, modify, and display your content solely for the purpose of providing the app's services.
        </Paragraph>
        <Paragraph>
          4.3. We reserve the right to remove any content that violates these Terms or is deemed inappropriate.
        </Paragraph>

        <Title level={5} className="section-title">5. Intellectual Property</Title>
        <Paragraph>
          5.1. All rights, titles, and interests in the app and its content, including but not limited to software, text, images, and logos, are owned by OD Shift or its licensors.
        </Paragraph>
        <Paragraph>
          5.2. You may not copy, modify, distribute, or create derivative works from the app without our express written consent.
        </Paragraph>

        <Title level={5} className="section-title">6. Limitation of Liability</Title>
        <Paragraph>
          6.1. To the fullest extent permitted by law, OD Shift and its affiliates, officers, and employees shall not be liable for any damages arising out of your use of the app.
        </Paragraph>
        <Paragraph>
          6.2. The app is provided "as is" without any warranties, either express or implied.
        </Paragraph>
        <Paragraph>
          6.3. OD Shift is not responsible for mistakes made by doctors or clinics for shift scheduling, missed shifts, and is not responsible for independent payment between both parties.
        </Paragraph>

        <Title level={5} className="section-title">7. Changes to the Terms</Title>
        <Paragraph>
          7.1. We reserve the right to update these Terms from time to time. Any changes will be posted within the app, and the "last updated" date will be revised.
        </Paragraph>
        <Paragraph>
          7.2. Your continued use of the app after changes are posted constitutes your acceptance of the updated Terms.
        </Paragraph>

        <Title level={5} className="section-title">8. Termination</Title>
        <Paragraph>
          8.1. We may suspend or terminate your access to the app at any time, for any reason, without notice.
        </Paragraph>
        <Paragraph>
          8.2. Upon termination, your right to use the app will cease immediately.
        </Paragraph>

        <Title level={5} className="section-title">9. Governing Law</Title>
        <Paragraph>
          These Terms are governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.
        </Paragraph>

        <Title level={5} className="section-title">10. Contact Us</Title>
        <Paragraph>
          If you have any questions about these Terms, please contact us at info.odshift@gmail.com.
        </Paragraph>
      </div>
      </Modal>
    );
  };
  
  




  return (
    <>
      <div className="page-background" style={{ display: 'flex', flexDirection: 'column', paddingTop: '80px', alignItems: 'center', height: '100vh' }}>
        {/* <AvatarNameCard /> */}
        {/* <TermsModal/> */}
        {/* <InfoPopover/> */}

        <>
            {/* HEADER SECTION */}

            <div style={{ padding: '0px 0', textAlign: 'center', backgroundColor: '#fff', position: 'relative', clipPath: 'inset(0)' }}>
            
                {/* Circular vector background */}
                <div 
                    style={{
                        position: 'absolute',
                        top: '-150px', // Adjust vertical offset as needed
                        left: '460px', // Adjust horizontal offset as needed
                        width: '1200px',
                        height: '900px',
                        background: 'radial-gradient(circle, rgba(0,123,255,0.3) 0%, transparent 70%)',
                        borderRadius: '50%',
                        zIndex: 0, // Ensure it is behind other content
                        filter: 'blur(80px)'
                    }}
                ></div>

            <Row justify="center" align="middle" gutter={[16, 16]} style={{     maxWidth: '1200px', margin: '0 auto', position: 'relative', zIndex: 1 }}>
                
                {/* Text on the left */}
                <Col xs={24} md={11} style={{ textAlign: 'left', padding: '0 0 0 100px' }}>
                    <Title style={{ fontSize: '46px', fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal' }}>
                        Connecting optometrists and clinics
                    </Title>
                    <Paragraph style={{ fontSize: '20px', color: '#667284' }}>
                        OD shift is your one stop shop to schedule and manage your shifts as a clinic owner or optometrist.
                    </Paragraph>
                    <Button className="primary-button button-size-large" style={{ marginTop: '12px' }}>
                        Sign up
                    </Button>
                </Col>

                {/* Image on the right */}
                <Col xs={24} md={13} style={{ textAlign: 'center',  padding: '0 0 0 0' }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/Mocks.png`}
                        alt="Find shifts on a laptop"
                        style={{ maxWidth: '100%', height: 'auto', marginTop: '50px' }} // Adjust marginTop if needed
                    />
                </Col>
            </Row>
        </div>

    


            {/* MOCKUP SECTION */}
            <div style={{ backgroundColor: '#F7F7F7', padding: '80px', paddingRight:'16px' }}>
            <Row justify="center" align="middle" gutter={[16, 16]} style={{ maxWidth: '1200px', margin: '0 auto' }}>
                

            <Col xs={24} md={16} style={{ textAlign: 'center', position: 'relative' }}>
                <img
                    src={`${process.env.PUBLIC_URL}/Mocks2.png`}
                    alt="Find shifts on a laptop"
                    style={{ maxWidth: '100%', height: 'auto', marginBottom: '16px' }}
                />
                </Col>

                <Col xs={24} md={8} style={{ padding: '16px', textAlign: 'left' }}>
                <Paragraph style={{ fontSize: '20px', color: '#667284'}}>
                Save time and effort by taking the manual work out of finding or filling shifts. 
                </Paragraph>
                </Col>



            </Row>
            </div>

            {/* INFOGRAPHIC SECTION */}
            <div style={{ backgroundColor: '#fff', padding: '80px' }}>
            <Row justify="center" align="middle" gutter={[16, 16]} style={{ maxWidth: '1200px', margin: '0 auto' }}>
                
                <Col xs={24} md={8} style={{ textAlign: 'center' }}>
                <img
                    src={`${process.env.PUBLIC_URL}/Doctor infographic.png`} 
                    alt="Scheduling shifts on a clinic interface"
                    style={{ maxWidth: '75%', height: 'auto', marginRight: '-80px' }}
                />
                </Col>

                <Col xs={24} md={8} style={{ textAlign: 'center' }}>
                <img
                    src={`${process.env.PUBLIC_URL}/Clinic infographic.png`} 
                    alt="Scheduling shifts on a clinic interface"
                    style={{ maxWidth: '75%', height: 'auto' }}
                />
                </Col>

                <Col xs={24} md={8} style={{ textAlign: 'center' }}>
                <img
                    src={`${process.env.PUBLIC_URL}/Calendar infographic.png`} 
                    alt="Scheduling shifts on a clinic interface"
                    style={{ maxWidth: '75%', height: 'auto', marginLeft: '-80px' }}
                />
                </Col>

            </Row>
            </div>

            {/* FOOTER SECTION */}
            <div style={{ backgroundColor: '#f7f7f7', padding: '60px' }}>
                <Row justify="center" align="middle" gutter={[16, 16]} style={{ maxWidth: '1200px', margin: '0 auto' }}>
                
                <Col xs={24} style={{ padding: '24px', textAlign: 'center' }}>
                    <Title level={3}>
                    Ready to level up your practice or career? Join OD Shift today!
                    </Title>
                    <Button 
                    className="primary-button button-size-large" style={{ marginTop: '24px' }} >
                    Sign up
                    </Button>
                </Col>

                </Row>
            </div>

                </>
            </div>
    </>
  );

}