import React, { useState, useRef, useEffect } from "react";
import { onError } from "../lib/errorLib";
import { v4 as uuidv4 } from 'uuid';
import { API } from "aws-amplify";
// import "./CreateOrEditShiftModal.css";
import { Button, Modal, Form, Input, notification } from 'antd';
import {
    CheckCircleTwoTone, CloseCircleTwoTone
} from '@ant-design/icons';

function LeaveFeedbackModal(props) {

    const {
        // isMobile,
        userData,
        isLeaveFeedbackModalOpen,
        setIsLeaveFeedbackModalOpen
    } = props

    const [feedbackNotes, setFeedbackNotes] = useState("");

    // create clinic button loading
    const [sendFeedbackButtonLoading, setSendFeedbackButtonLoading] = useState(false);

    // toast notifications
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const { TextArea } = Input;

    function validateSendFeedback() {
        if (feedbackNotes !== ""){
            return true;
        } else {
            return false;
        }
    }

    async function leaveFeedback() {
        setSendFeedbackButtonLoading(true);

        // send feedback params
        const sendFeedbackParams = {
            user: userData.firstName + " " + userData.lastName,
            userEmail: userData.email,
            feedbackNotes: feedbackNotes
        }
        console.log("sendFeedbackParams: ", sendFeedbackParams);

        // create clinic logic
        sendFeedback_CallApi(sendFeedbackParams);
    }

    function sendFeedback_CallApi(params) {
        try {
            return API.post("od-api", "/sendFeedback", { //sendFeedback.js
                body: params,
            }).then((data) => {
                console.log("send feedback data response: ", data);
                if (data.statusCode === 200) { // check response to see if succesful or not.
                    openNotification('Sent Feedback Succesfully! Thank you!');
                    const parsedBody = JSON.parse(data.body);
                    console.log("parsedBody clinic creation: ", parsedBody);

                    // close generic modal telling user to create a clinic
                    setIsLeaveFeedbackModalOpen(false);
                    setSendFeedbackButtonLoading(false);

                } else {
                    openNotification('Sending Feedback Failed');
                    setIsLeaveFeedbackModalOpen(false);
                    setSendFeedbackButtonLoading(false);
                }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
            setSendFeedbackButtonLoading(false);
        }
    };

    const openNotification = (message) => {
        api.open({
            message: message,
            icon: message === "Clinic Creation Failed" ?
                <CloseCircleTwoTone style={{ color: '#108ee9' }} />
                :
                <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
        });
    };

    const closeModal = () => {
        setIsLeaveFeedbackModalOpen(false);
    }

    return (
        <>
            {contextHolder}
            <Modal
                open={isLeaveFeedbackModalOpen}
                title="Leave Feedback"
                onCancel={closeModal}
                destroyOnClose={true}  // Ensures the form component is destroyed when modal is closed - clears input fields..
                footer={
                    <>
                        <Button key="back" className="secondary-button button-size-default" onClick={closeModal}>
                            cancel
                        </Button>
                        <Button key="sendFeedback" className="primary-button button-size-default" loading={sendFeedbackButtonLoading} onClick={leaveFeedback} disabled={!validateSendFeedback()}>
                            Send Feedback
                        </Button>
                    </>
                }
            >
                <>
                    <br></br>
                    <Form
                        // autoComplete="off"
                        layout="vertical"
                    >
                        <p style={{ marginBottom: '40px' }}>If you have ideas on how we can improve OD Shift, or we are missing something you need, we would love to hear! Either leave feedback below, or send an email to <b>info.odshift@gmail.com</b>.</p>
                        <Form.Item
                            style={{ marginTop: -16 }}
                            name="leave feedback"
                            label="Feedback:"
                        >
                            <TextArea
                                rows={2}
                                placeholder="add feedback"
                                style={{ marginTop: -2 }}
                                value={feedbackNotes}
                                maxLength={600}
                                onChange={(e) => setFeedbackNotes(e.target.value)}
                            />
                        </Form.Item>

                    </Form>
                </>
            </Modal>
        </>

    );
}

export default LeaveFeedbackModal