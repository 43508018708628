
import React from "react";
import { Button, Modal, Spin} from 'antd';

function GenericModal(props) {

    const { 
        handleCancel,
        modalTitle,
        isGenericModalOpen,
        bodyText,
        showButton,
        showCancelButton,
        buttonFunction,
        buttonText,
        width,
        showLoadingSpinner, // general centered larger loading spinner
        showButtonLoading,  // loading spinner within primary button
        zIndex,
    } = props

    return(
        <>
            <Modal
                open={isGenericModalOpen}
                title={modalTitle}
                onCancel={handleCancel}
                centered
                width={width}
                closable={false}
                zIndex={zIndex}
                footer={
                    <>
                        {showCancelButton == 'true' ?
                        <>
                            <Button className="secondary-button button-size-large" onClick={handleCancel}>
                                cancel
                            </Button>
                        </> 
                        :
                        <></>}

                        {showButton == 'true' ?
                        <>
                            <Button loading={showButtonLoading} className="primary-button button-size-large" onClick={buttonFunction}>
                                {buttonText}
                            </Button>
                        </> 
                        :
                        <></>}
                    </>
                }
            >
                <p>
                    {bodyText}
                </p>
                {
                    showLoadingSpinner 
                    ? 
                        <Spin style={{marginLeft: '0px'}} size="large" />
                    : 
                        <></>
                }
                

            </Modal>
        </>

    );
}

export default GenericModal