
import React from "react"
import { useNavigate } from "react-router-dom";
import "../index.css";

export default function Logo(props) {

    const { isMobile } = props
    const nav = useNavigate();

    function handleMenuItemClick(key) {
        console.log("key clicked: " + key);
        nav("/");
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }} key="logo" onClick={({ key }) => {handleMenuItemClick(key)}}>
            <img src={`${process.env.PUBLIC_URL}/GlassesLogo.png`} alt="logo" style={{ height: isMobile ? '16px' : '16px', marginLeft: '-22px', marginRight: '8px' }} />
            {/* Optional: add a project name or any other text next to the logo */}
            <h5 className="openSans" style={{ color: 'white', marginLeft: isMobile ? '0px' : '0px', marginTop: isMobile ? '8px' : '8px', marginRight: isMobile ? '-22px' : '8px' }}>OD Shift</h5>
        </div>
    )
}

{/*<span style={{ color: 'white', fontWeight: 'bold', marginLeft: '5px' }}>OD Shift</span> */}
