// src/contexts/GoogleMapsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const GoogleMapsContext = createContext();

const libraries = ['places']; // Add other libraries you need

export const GoogleMapsProvider = ({ children }) => {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const [placesService, setPlacesService] = useState(null);
    const [geocoder, setGeocoder] = useState(null);

    useEffect(() => {
        if (isLoaded && window.google) {
            setPlacesService(new window.google.maps.places.PlacesService(document.createElement('div')));
            setGeocoder(new window.google.maps.Geocoder());
        }
    }, [isLoaded]);

    return (
        <GoogleMapsContext.Provider value={{ isLoaded, loadError, placesService, geocoder }}>
            {children}
        </GoogleMapsContext.Provider>
    );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);