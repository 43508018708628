import React, { useState, useEffect } from "react";
import { onError } from "../lib/errorLib";
import { v4 as uuidv4 } from 'uuid';
import { API } from "aws-amplify";
import AddressAutoComplete from "../containers/AddressAutoComplete"

import { Button, Modal, Form, Input, notification } from 'antd';

import {
    CheckCircleTwoTone,
    CloseCircleTwoTone } from '@ant-design/icons';

    // This is only handling Join Org... Create Org Logic happens now in CreateClinicModal.js
function JoinOrgModal(props) {

    const { 
        handleCancel,
        userData,
        onLoginEvent, // refresh page after clinic is added.
        setIsWelcomModalOpen,
        setIsJoinOrgModalOpen,
        isJoinOrgModalOpen
    } = props

    const [orgId, setOrgId] = useState(""); 
    const [joinOrgButtonLoading, setJoinOrgButtonLoading] = useState(false);

    // notifications
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();

    function validateOrgId() {
        if(orgId !== ""){
            return true;
        }else{
            return false;
        }
    }

    const joinOrg = () => {
        setJoinOrgButtonLoading(true);
        const updatedUserData = {
            userType: userData.userType,
            orgId: orgId,
        }
        console.log("updatedUserData: ", updatedUserData);
        updateUserDataJoinOrg(updatedUserData);
        setIsJoinOrgModalOpen(false);
    };

    function updateUserDataJoinOrg(userData) {
        try {
            // PUT to /userData is update
          return API.put("od-api", "/userData", {
            body: userData,
          }).then((data) => {
            console.log("join org response: ", data);
            if(data.statusCode === 200){ // check response to see if succesful or not.
              openNotification('Joined Team Succesfully');
              setIsWelcomModalOpen(false);
    
              // the page will not resfresh until we tell it to. Pull updated userData.
              onLoginEvent();
              setJoinOrgButtonLoading(false);
    
            }else{
              openNotification('Org Does Not Exist');
              setJoinOrgButtonLoading(false);
            }
            // do other things
          });
        } catch (e) {
          onError(e);
          console.log("ERROR: ", e);
        }
    };

    const openNotification = (message) => {
        api.open({
            message: message,
            icon: message === "Org Does Not Exist" ? 
            <CloseCircleTwoTone style={{ color: '#108ee9' }} /> 
            : 
            <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
        });
    };

    // For Join Existing Org
    const handleOrgIdChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setOrgId(event.target.value);
    };

    return(
        <>
            {contextHolder}
            <Modal
            open={isJoinOrgModalOpen}
            title="Join An Existing Clinic"
            onCancel={handleCancel}
            footer={ 
                <>
                    <Button key="backCreateOrg" className="secondary-button button-size-large" onClick={handleCancel}>
                        back   
                    </Button>
                    <Button key="createOrg" loading={joinOrgButtonLoading} className="primary-button button-size-large" onClick={joinOrg} disabled={!validateOrgId()}>
                        Join Clinic
                    </Button>
                </>
            }
            >
                <>
                    <br></br>
                    <Form>
                        <Form.Item name="org id" label="Team Id:" rules={[{ required: true }]}>
                        <Input placeholder="xxxx-xxxx-xxxx-xxxx"
                            type="text"
                            value={orgId}
                            rules={[{ required: true }]}
                            onChange={handleOrgIdChange}
                            style={{marginBottom: '14px'}}
                            />
                        </Form.Item>
                    </Form>
                </>
            </Modal>
        </>

    );
}

export default JoinOrgModal