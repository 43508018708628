import React, { useState, useRef, useEffect } from "react";
import { onError } from "../../lib/errorLib";
import { v4 as uuidv4 } from 'uuid';
import { API } from "aws-amplify";
import dayjs from 'dayjs';
import "./ClinicInformation_Profile.css";
import AddressAutoComplete from "../../containers/AddressAutoComplete"
import { useNavigate } from "react-router-dom";
import { doctorHourTimes } from '../profileComponents/doctorHourTimes';
import { useGoogleMaps } from '../../contexts/GoogleMapsContext';

import { Button, Modal, Form, Input, notification, Checkbox, TimePicker, Popover, Col, Row, Select, Card, Typography} from 'antd';

import {
    CheckCircleTwoTone, 
    CloseCircleTwoTone, 
    InfoCircleOutlined, 
    EditOutlined, 
    CloseCircleOutlined,
    CalendarOutlined
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

export function ClinicInformation_Profile(props) {

    const { 
        handleCancel,
        isMobile,
        userData,
        onLoginEvent, // refresh page after clinic is added.
        // setIsCreateClinicModalOpen,
        // isCreateClinicModalOpen,
        // setIsGenericModalOpen,
        rePullClinicData,
        clinic,
        index,
        setCurrentlySelectedClinicCalendar
    } = props

    const [clinicName, setClinicName] = useState(clinic.clinicName); 
    const [clinicWebsite, setClinicWebsite] = useState(clinic.clinicWebsite); 
    const [clinicAddress, setClinicAddress] = useState(clinic.clinicAddress); 
    const [clinicPhoneNumber, setClinicPhoneNumber] = useState(clinic.clinicPhoneNumber);
    const [clinicEmail, setClinicEmail] = useState(clinic.clinicEmail);
    const [clinicDoctorHours, setClinicDoctorHours] = useState(clinic.clinicDoctorHours);
    const [clinicDoctorHoursSimpleArray, setClinicDoctorHoursSimpleArray] = useState("");
    const [clinicPay, setClinicPay] = useState(clinic.clinicPay);
    const [clinicNumberOfExamLanes, setClinicNumberOfExamLanes] = useState(clinic.clinicNumberOfExamLanes);
    const [clinicGlassesContactsBool, setClinicGlassesContactsBool] = useState(clinic.clinicGlassesContactsBool);
    const [clinicMedicalBool, setClinicMedicalBool] = useState(clinic.clinicMedicalBool);
    const [clinicNotes, setClinicNotes] = useState(clinic.clinicNotes);

    const [shiftDoctorHours_StartTime, setShiftDoctorHours_StartTime] = useState("");
    const [shiftDoctorHours_EndTime, setShiftDoctorHours_EndTime] = useState("");

    // edit clinic button loading
    const [editClinicButtonLoading, setEditClinicButtonLoading] = useState(false);
    const [isEditClinicModalOpen, setIsEditClinicModalOpen] = useState(false);

    // toast notifications
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const nav = useNavigate();

    const { TextArea } = Input;
    const inputRef = useRef(null); // for address autoComplete

    // We need to check in validation that if one of the shiftDoctorHours_StartTime or shiftDoctorHours_EndTime is set, then the other must be set.

    function validateCreateClinic() {
        if(
            clinicName !== "" &&
            clinicWebsite !== "" &&
            clinicAddress !== "" &&
            clinicPhoneNumber !== "" &&
            clinicEmail !== "" &&
            // clinicDoctorHours !== "" && // optional
            // clinicPay !== "" && // optional
            clinicNumberOfExamLanes !== ""
            )
        {
            if(clinicGlassesContactsBool || clinicMedicalBool){

                // console.log("clinicDoctorHoursSimpleArray: ", clinicDoctorHoursSimpleArray);
                // console.log("shiftDoctorHours_StartTime: ", shiftDoctorHours_StartTime);
                // console.log("shiftDoctorHours_EndTime: ", shiftDoctorHours_EndTime);

                // make sure that you need to put in both doctor hours strat and end, and cannot just put in one, but also can take out both and have it work.
                if (clinicDoctorHoursSimpleArray[0] === "" || clinicDoctorHoursSimpleArray[1] === ""){
                    return false;
                }

                // Check to make sure that not only one of the shiftDoctorHours_StartTime or shiftDoctorHours_EndTime is set.
                if (shiftDoctorHours_StartTime !== "" && shiftDoctorHours_EndTime === "" || shiftDoctorHours_EndTime !== "" && shiftDoctorHours_StartTime === "") {
                    return false;
                }

                return true;
            }
        }else{
            return false;
        }
    }

    // make sure the state is populated on modal open if doctor hours already extist
    useEffect(() => {
        // Check if clinic.clinicDoctorHours exists and has values
        if (clinic.clinicDoctorHours && clinic.clinicDoctorHours.length === 2) {
            setClinicDoctorHoursSimpleArray(clinic.clinicDoctorHours);
            setShiftDoctorHours_StartTime(clinic.clinicDoctorHours[0]);
            setShiftDoctorHours_EndTime(clinic.clinicDoctorHours[1]);
        }
    }, [clinic.clinicDoctorHours]); // Only run when clinic.clinicDoctorHours changes


    // Initialize Google Maps API
    const { isLoaded } = useGoogleMaps(); 

    const updateClinic = async () => {
        console.log("update clinic..");
        setEditClinicButtonLoading(true);
        let geocodedAddressResult = {};
        if(isLoaded){
            // Execute Geocoding here to get Lat Long and save out to dynamo for future use...
            const geocoder = new window.google.maps.Geocoder();
            const address = clinicAddress; // needs variable name address to work with google maps api..
        
            console.log("geocoding clinic address to save into dynamodb, and not need to geocode again downstream.");
            console.log("clinicAddress for geocoding: ", address);
            const geocodingResponse = await geocoder.geocode({address}); // this clinic address is not working...
            console.log("geocodingResponse: ", geocodingResponse); // full geocode object, dont need everything?
            if (geocodingResponse.results[0]) {
                const { lat, lng } = geocodingResponse.results[0].geometry.location;
                geocodedAddressResult = {
                    lat: lat(),
                    lng: lng(),
                    address,
                    clinicName: `${geocodingResponse.results[0].formatted_address.split(",")[0]}`,
                    metadata: `Clinic Info: ${address}`
                };
            }
        }
        console.log("geocoded adress results: ", geocodedAddressResult);
        console.log("clinicDoctorHoursSimpleArray: ", clinicDoctorHoursSimpleArray);
        console.log("clinic.clinicDoctorHours: ", clinic.clinicDoctorHours);
        console.log("clinicDoctorHours: ", clinicDoctorHours);

        // TODO:
        // update clinicDoctorHours if the user has added a value for shiftDoctorHours_StartTime or shiftDoctorHours_EndTime
        // Check if this works....
        let updatedClinicDoctorHours = [];
        if (shiftDoctorHours_StartTime !== "" && shiftDoctorHours_EndTime !== ""){
            // console.log("updating both the start time and end time");
            // console.log("shiftDoctorHours_StartTime: ", shiftDoctorHours_StartTime);
            // console.log("shiftDoctorHours_EndTime: ", shiftDoctorHours_EndTime);

            setClinicDoctorHours([shiftDoctorHours_StartTime, shiftDoctorHours_EndTime]);
            updatedClinicDoctorHours = [shiftDoctorHours_StartTime, shiftDoctorHours_EndTime];
        } else if (shiftDoctorHours_StartTime !== ""){
            // console.log("only updating the start time");
            // console.log("clinicDoctorHours[1]: ", clinicDoctorHours[1]);
            // console.log("shiftDoctorHours_StartTime.value: ", shiftDoctorHours_StartTime.value);

            setClinicDoctorHours([shiftDoctorHours_StartTime, clinicDoctorHours[1]]);
            updatedClinicDoctorHours = [shiftDoctorHours_StartTime, clinicDoctorHours[1]];
        } else if (shiftDoctorHours_EndTime !== ""){
            // console.log("only updating the end time");
            // console.log("shiftDoctorHours_EndTime.value: ", shiftDoctorHours_EndTime.value);
            // console.log("clinicDoctorHours[0]: ", clinicDoctorHours[0]);

            setClinicDoctorHours([clinicDoctorHours[0], shiftDoctorHours_EndTime]);
            updatedClinicDoctorHours = [clinicDoctorHours[0], shiftDoctorHours_EndTime];
        }else{
            updatedClinicDoctorHours = clinicDoctorHours;
        }
        console.log("updatedClinicDoctorHours: ", updatedClinicDoctorHours);

        
        // clinic data params
        const clinicDataParams = {
          orgId: clinic.orgId,
          clinicId: clinic.clinicId,
          clinicName: clinicName,
          clinicWebsite: clinicWebsite,
          clinicAddress: clinicAddress,
          clinicLat: geocodedAddressResult.lat,
          clinicLng: geocodedAddressResult.lng,
          clinicPhoneNumber: clinicPhoneNumber,
          clinicEmail: clinicEmail,
          //   clinicDoctorHours: clinicDoctorHoursSimpleArray === "" ? clinic.clinicDoctorHours : clinicDoctorHoursSimpleArray ,
          //   clinicDoctorHours: clinicDoctorHoursSimpleArray === "" ? clinicDoctorHours : clinicDoctorHoursSimpleArray, // send simple time string array to dynamodb
          //   clinicDoctorHours: clinicDoctorHoursSimpleArray === "" ? updatedClinicDoctorHours : [shiftDoctorHours_StartTime, shiftDoctorHours_EndTime], // send simple time string array to dynamodb
          clinicDoctorHours: updatedClinicDoctorHours,
          clinicPay: clinicPay,
          clinicNumberOfExamLanes: clinicNumberOfExamLanes,
          clinicGlassesContactsBool: clinicGlassesContactsBool,
          clinicMedicalBool: clinicMedicalBool,
          clinicNotes: clinicNotes
        }
        console.log("update clinicDataParams: ", clinicDataParams);
    
        // create clinic logic
        updateClinic_CallApi(clinicDataParams);
    };
    
    function updateClinic_CallApi(clinicData) {
        try {
            return API.put("od-api", "/clinicData", { // updateClinicData.js
                body: clinicData,
            }).then((data) => {
            console.log("update clinic data response: ", data);
            if(data.statusCode === 200){ // check response to see if succesful or not.
                openNotification('Updated Clinic Succesfully');
                const parsedBody = JSON.parse(data.body);
                console.log("parsedBody clinic creation: ", parsedBody);

                setIsEditClinicModalOpen(false);
                setEditClinicButtonLoading(false);

                // the page will not resfresh until we tell it to. Pull updated userData.
                rePullClinicData(clinic.clinicId);
                onLoginEvent();
            }else{
                openNotification('Clinic Update Failed');
                setEditClinicButtonLoading(false);
                setIsEditClinicModalOpen(false);
            }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
            setEditClinicButtonLoading(false);
        }
    };

    const openNotification = (message) => {
        api.open({
            message: message,
            icon: message === "Clinic Creation Failed" ? 
            <CloseCircleTwoTone style={{ color: '#108ee9' }} /> 
            : 
            <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
        });
    };
        
    // For Create New Clinic
    const handleClinicNameChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicName(event.target.value);
    };

    const handleClinicWebsiteChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicWebsite(event.target.value);
    };

    const handleClinicPhoneNumberChange = (event) => {
        // console.log("event.target.id: ", event.target.value);

        const formattedPhoneNumber = formatPhoneNumber(event.target.value)
        setClinicPhoneNumber(formattedPhoneNumber);
    };

    function formatPhoneNumber(value) {
        if (!value) return value;
        // Remove all non-numeric characters
        const phoneNumber = value.replace(/[^\d]/g, '');
      
        // Format the phone number
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) {
          return phoneNumber;
        } else if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        } else {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
        }
      }

    const handleClinicEmailChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicEmail(event.target.value);
    };

    const handleClinicPayChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setClinicPay(event.target.value);
    };

    const handleNumberOfExamLanesChange = (event) => {
        console.log("event.target.id: ", event.target.value);
        setClinicNumberOfExamLanes(event.target.value);
    };

    const InfoPopover = () => {
        return (
          <Popover
            content={
              <div style={{ maxWidth: '250px' }}>
                Set the doctor's usual hours here. You can always edit these hours here, or on the individual shifts.
              </div>
            }
            placement="right"
          >
            <Button
              type="text"
              shape="circle"
              icon={<InfoCircleOutlined style={{ color: '#1890ff', fontSize: '16px' }} />}
            />
          </Popover>
        );
      };
      const clinicExamTypes = (clinic) => {
        let clinicExamTypes = "";
        if(clinic.clinicGlassesContactsBool && !clinic.clinicMedicalBool){
          clinicExamTypes = "Routine"
        }else if(clinic.clinicMedicalBool && !clinic.clinicGlassesContactsBool){
          clinicExamTypes = "Medical"
        }else if(clinic.clinicGlassesContactsBool && clinic.clinicMedicalBool){
          clinicExamTypes = "Routine, Medical"
        }
        return clinicExamTypes
      };

      const clinicDefaultDoctorHours = (clinic) => {

            const startTime = clinic.clinicDoctorHours[0];
            const endTime = clinic.clinicDoctorHours[1];

            // const startTime = clinic.clinicDoctorHoursSimpleArray[0];
            // const endTime = clinic.clinicDoctorHoursSimpleArray[1];
            // console.log("endTime: ", startTime);
            // console.log("startTime: ", startTime);

            // check if pay and doctor hours were set when creating the clinic
            let checkedPay;
            // console.log("clinic.clinicPay: ", clinic.clinicPay);
            if (clinic.clinicPay === "" || clinic.clinicPay === "0") {
                // console.log("clinic typical pay was not provided");
                checkedPay = "pay not provided"
            } else {
                checkedPay = "$" + clinic.clinicPay;
            }

            // check if clinic hours have not been set and say "not provided"
            let checkedDoctorHours;
            if (startTime === undefined && endTime === undefined ) {
                // console.log("clinic typical hours were not provided");
                checkedDoctorHours = "hours not provided"
            } else {
                checkedDoctorHours = convertMilitaryToStandardTime(startTime) + " - " + convertMilitaryToStandardTime(endTime);
            }

            const clinicDoctorHoursAndPay = checkedDoctorHours + " | " + checkedPay;
            // console.log("clinicDoctorHoursAndPay: ", clinicDoctorHoursAndPay);
            return clinicDoctorHoursAndPay

      };

        function convertMilitaryToStandardTime(militaryTime) {
            // Split the input into hours and minutes
            const [hours, minutes] = militaryTime.split(':').map(Number);
            // Calculate the period (AM or PM)
            const period = hours >= 12 ? 'pm' : 'am';
            // Convert hours to 12-hour format
            const standardHours = hours % 12 || 12; // Converts 0 to 12 for midnight and 12-hour conversion
            // Format the final time string
            return `${standardHours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
        }

        const closeModal = () => {
            setIsEditClinicModalOpen(false);
            setShiftDoctorHours_StartTime("");
            setShiftDoctorHours_EndTime("");

        }

        const openEditClinicModal = () => {
            setIsEditClinicModalOpen(true);
            
            // set values if previously cleared
            setClinicDoctorHours(clinic.clinicDoctorHours);
            setClinicPhoneNumber(clinic.clinicPhoneNumber);
            setClinicAddress(clinic.clinicAddress);
            setClinicEmail(clinic.clinicEmail);
            setClinicNumberOfExamLanes(clinic.clinicNumberOfExamLanes);
        }

        const handleShiftDoctorHoursStartTimeChange = (value) => {
            console.log("start time value: ", value.value);
            setShiftDoctorHours_StartTime(value.value);

            if (shiftDoctorHours_EndTime !== "") {
                console.log("updating clinicDoctorHoursSimpleArray with value.value and shiftDoctorHours_EndTime");
                setClinicDoctorHoursSimpleArray([value.value, shiftDoctorHours_EndTime]);
            } else if (clinicDoctorHoursSimpleArray[1] !== "") {
                console.log("updating clinicDoctorHoursSimpleArray with value.value and clinicDoctorHoursSimpleArray[1]");
                setClinicDoctorHoursSimpleArray([value.value, clinicDoctorHoursSimpleArray[1]]);
            }
        };

        const handleShiftDoctorHoursEndTimeChange = (value) => {
            console.log("end time value: ", value.value);
            setShiftDoctorHours_EndTime(value.value);

            if (shiftDoctorHours_StartTime !== "") {
                console.log("updating clinicDoctorHoursSimpleArray with shiftDoctorHours_StartTime and value.value");
                setClinicDoctorHoursSimpleArray([shiftDoctorHours_StartTime, value.value]);
            } else if (clinicDoctorHoursSimpleArray[0] !== "") {
                console.log("updating clinicDoctorHoursSimpleArray with clinicDoctorHoursSimpleArray[0] and value.value");
                setClinicDoctorHoursSimpleArray([clinicDoctorHoursSimpleArray[0], value.value]);
            }
        };

        const openClinicCalendar = () => {
            // console.log("Open clinic calendar here...");
            setCurrentlySelectedClinicCalendar(clinic);
            sessionStorage.setItem("currentlySelectedClinicCalendar", clinic.clinicId);
            nav("/calendar");

            // Find and access the currect button in the clinic names list, to set it's class name to 'custom-selected'
            // Change the button to selected....
            setTimeout(() => {
                const antMenu = document.querySelector('.menu-clinics'); // Get the main menu container
                if (antMenu) {
                    const clinicMenuList = antMenu.querySelector('.ant-menu-submenu'); // Find the submenu
                    const clinicList = clinicMenuList?.querySelector('.ant-menu'); // Get the nested menu (if it exists)

                    if (clinicList) {
                        // Select all menu items
                        const clinicMenuItems = clinicList.querySelectorAll('li.ant-menu-item');
                        // console.log('clinicMenuItems: ', clinicMenuItems);

                        // Remove the custom-selected class from all items, then add it back to the correct item of the calendar that you are selecting
                        // REFACTOR.. don't use DOM manipulation
                        clinicMenuItems.forEach((item) => {

                            item.classList.remove('custom-selected');
                            item.classList.remove('ant-menu-item-selected');

                            const menuTitle = item?.querySelector('.ant-menu-title-content');
                            // console.log("menuTitle: ", menuTitle);
                            const antBadge = menuTitle?.querySelector('.ant-badge');
                            // console.log("antBadge remove: ", antBadge);
                            antBadge?.classList.remove('custom-selected');
                            // console.log("antBadge?.innerHTML : ", antBadge?.innerHTML);
                            
                            // console.log("item.firstChild.innerHTML: ", item.firstChild.innerHTML);
                            // console.log("clinic.clinicName: ", clinic.clinicName);
                            if (item.firstChild.innerHTML === clinic.clinicName){
                                item.classList.add('custom-selected');
                                item.classList.add('ant-menu-item-selected');
                            } else if (menuTitle?.innerHTML === clinic.clinicName){
                                // find children if notification is set..
                                const menuTitle = item?.querySelector('.ant-menu-title-content');
                                // console.log("menuTitle: ", menuTitle);
                                menuTitle?.classList.add('custom-selected');
                            } else if (antBadge?.innerHTML.includes(clinic.clinicName)){
                                // const antBadge = menuTitle?.querySelector('.ant-badge');
                                // console.log("antBadge add: ", antBadge);
                                antBadge?.classList.add('custom-selected');
                            }

                        });
                    }
                }
            }, 100); // Wait for 100ms before querying

        }

    return(
        <>
            {contextHolder}
            <div 
                style={{ display: 'flex', justifyContent: 'center', width: '100%'}}
                key={index}
            >
                <Card
                    bordered={true}
                    style={{ width: isMobile ? '90%' : '75%', padding: '0px', marginBottom: '4px' }} 
                    // key={index}
                    >
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Title level={5} style={{ marginBottom: '16px', marginTop: '-8px' }}>{clinic.clinicName}</Title>
                        </Col>
                        <Col style={{ marginBottom: isMobile ? "10px" : "" }}>
                            <Button
                                icon={<CalendarOutlined />}
                                className="secondary-button button-size-default"
                                onClick={openClinicCalendar}
                            >Open Calendar</Button>
                            <Button 
                                icon={<EditOutlined />} 
                                className="secondary-button button-size-default" 
                                onClick={openEditClinicModal} 
                            >Edit</Button>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginBottom: '-24px' }}>
                        <Col xs={24} sm={12}>
                        <Text style={{ color: '#667284' }}>Clinic address</Text>
                        <Paragraph>{clinic.clinicAddress}</Paragraph>

                        <Text style={{ color: '#667284' }}>Phone number</Text>
                        <Paragraph>{clinic.clinicPhoneNumber}</Paragraph>

                        {/* <Text style={{ color: '#667284' }}>Email address</Text>
                        <Paragraph>{clinic.email}</Paragraph> */}

                        <Text style={{ color: '#667284'}}>Website</Text>
                        <Paragraph style={{ marginBottom: '16px'}}>{clinic.clinicWebsite}</Paragraph>
                        </Col>

                        <Col xs={24} sm={12}>
                        <Text style={{ color: '#667284' }}>Exam types</Text>
                        <Paragraph>{(clinicExamTypes(clinic))}</Paragraph>
                        {/* <p>{(clinicExamTypes(clinic))}</p> */}

                        <Text style={{ color: '#667284' }}>Typical doctor hours and pay</Text>
                        <Paragraph>{(clinicDefaultDoctorHours(clinic))}</Paragraph>
                        {/* <p>{(clinicDefaultDoctorHours(clinic))}</p> */}

                        <Text style={{ color: '#667284' }}>Clinic Notes</Text>
                            <Paragraph>{clinic.clinicNotes === "" ? "notes not provided" : clinic.clinicNotes }</Paragraph>
                        </Col>
                    </Row>
                </Card>

                <Modal
                    open={isEditClinicModalOpen}
                    title="Edit Clinic Information"
                    onCancel={closeModal}
                    destroyOnClose={true}  // Ensures the form component is destroyed when modal is closed - clears input fields..
                    footer={ 
                        <>
                            <Button key="closeModal" className="secondary-button button-size-default" onClick={closeModal}>
                                back
                            </Button>
                            <Button key="joinClinic" className="primary-button button-size-default" loading={editClinicButtonLoading} onClick={updateClinic} disabled={!validateCreateClinic()}>
                                Save
                            </Button>
                        </>

                    }
                >
                    <>
                        <Form 
                        layout="vertical"
                        autoComplete="off"
                        initialValues={{
                            clinicName: clinic.clinicName,
                            clinicWebsite: clinic.clinicWebsite,
                            clinicAddress: clinic.clinicAddress,
                            clinicPhoneNumber: clinic.clinicPhoneNumber,
                            clinicEmail: clinic.clinicEmail,
                            clinicDoctorHours: clinic.clinicDoctorHours[0] !== undefined ?
                                [
                                    dayjs(clinic.clinicDoctorHours[0], 'HH:mm'),
                                    dayjs(clinic.clinicDoctorHours[1], 'HH:mm')
                                ]
                            : 
                                []
                            ,
                            clinicPay: clinic.clinicPay,
                            clinicNumberOfExamLanes: clinic.clinicNumberOfExamLanes,
                            clinicGlassesContactsBool: clinic.clinicGlassesContactsBool,
                            clinicMedicalBool: clinic.clinicMedicalBool,
                            clinicNotes: clinic.clinicNotes
                          }}
                        >
                            <Form.Item name="clinicName" label="Clinic Name" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="clinic name"
                                    type="text"
                                    style={{ marginTop: -12}} 
                                    value={clinicName}
                                    rules={[{ required: true }]}
                                    onChange={handleClinicNameChange}
                                    />
                            </Form.Item>
                            <Form.Item name="clinicWebsite" label="Website" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="website"
                                    type="text"
                                    style={{ marginTop: -12}} 
                                    value={clinicWebsite}
                                    rules={[{ required: true }]}
                                    onChange={handleClinicWebsiteChange}
                                    />
                            </Form.Item>
                            <Form.Item name="clinicAddress" label="Address" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                {/* Only show google api autocomplete if the api is loaded */}
                                {isLoaded ?
                                    <AddressAutoComplete
                                        setAddress={setClinicAddress}
                                        previousAddress={clinic.clinicAddress}
                                    ></AddressAutoComplete>
                                    :
                                    <></>
                                }
                            </Form.Item>
                            <Form.Item name="clinicPhoneNumber" label="Phone Number" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="phone number"
                                    type="text"
                                    style={{ marginTop: -12}} 
                                    value={clinicPhoneNumber}
                                    rules={[{ required: true }]}
                                    onChange={handleClinicPhoneNumberChange}
                                    />
                            </Form.Item>
                            <Form.Item name="clinicEmail" label="Contact Email" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="contact email"
                                    type="text"
                                    style={{ marginTop: -12}} 
                                    value={clinicEmail}
                                    rules={[{ required: true }]}
                                    onChange={handleClinicEmailChange}
                                    />
                            </Form.Item>
                            <Form.Item
                                // name="clinicDoctorHours"
                                label={
                                    <span>
                                        Typical Doctor Hours (optional) 
                                        <InfoPopover />
                                    </span>
                                }
                                style={{ marginBottom: 4 }}
                            >
                                <>
                                    {/* Start Time */}
                                    <Select
                                        showSearch
                                        labelInValue
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option?.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                        suffixIcon={
                                            clinicDoctorHours[0] !== "" || shiftDoctorHours_StartTime !== ""  ? (
                                                <CloseCircleOutlined
                                                    onClick={() => { 
                                                        setShiftDoctorHours_StartTime("");
                                                        setShiftDoctorHours_EndTime("");
                                                        setClinicDoctorHours([]);
                                                        setClinicDoctorHoursSimpleArray([]);
                                                     }}
                                                    style={{ color: "gray", cursor: "pointer" }}
                                                />
                                            ) : null
                                        }
                                        style={{ width: 140, marginRight: '12px' }}
                                        placeholder="start time"
                                        options={doctorHourTimes}
                                        // value={shiftDoctorHours_StartTime === "" ? clinicDoctorHoursSimpleArray[0] : shiftDoctorHours_StartTime}
                                        value={shiftDoctorHours_StartTime === "" ? clinicDoctorHours[0] : shiftDoctorHours_StartTime}
                                        onChange={(value) => {
                                            handleShiftDoctorHoursStartTimeChange(value);
                                        }}
                                    />
                                    {/* End Time */}
                                    <Select
                                        showSearch
                                        labelInValue
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option?.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                        suffixIcon={
                                            clinicDoctorHours[1] !== "" || shiftDoctorHours_EndTime !== "" ? (
                                                <CloseCircleOutlined
                                                    onClick={() => { 
                                                        setShiftDoctorHours_EndTime("");
                                                        setShiftDoctorHours_StartTime("");
                                                        setClinicDoctorHours([]);
                                                        setClinicDoctorHoursSimpleArray([]);
                                                    }}
                                                    style={{ color: "gray", cursor: "pointer" }}
                                                />
                                            ) : null
                                        }
                                        style={{ width: 140 }}
                                        placeholder="end time"
                                        options={doctorHourTimes}
                                        // value={shiftDoctorHours_EndTime === "" ? clinicDoctorHoursSimpleArray[1] : shiftDoctorHours_EndTime}
                                        value={shiftDoctorHours_EndTime === "" ? clinicDoctorHours[1] : shiftDoctorHours_EndTime}
                                        onChange={(value) => {
                                            handleShiftDoctorHoursEndTimeChange(value);
                                        }}
                                    />
                                </>
                            </Form.Item>
                            <Form.Item colon={false} name="clinicPay" label="Pay ($USD) (optional) " style={{ marginBottom: 4}}>
                                <Input placeholder="($)"
                                    style={{ marginTop: -12}} 
                                    type="number"
                                    value={clinicPay}
                                    onChange={handleClinicPayChange}
                                    onWheel={(e) => e.target.blur()}
                                    />
                            </Form.Item>
                            <Form.Item colon={false} name="clinicNumberOfExamLanes" label="Number of Exam Lanes" style={{ marginBottom: 4}} rules={[{ required: true }]}>
                                <Input placeholder="number of exam lanes"
                                    style={{ marginTop: -12}} 
                                    type="number"
                                    value={clinicPay}
                                    onChange={handleNumberOfExamLanesChange}
                                    onWheel={(e) => e.target.blur()}
                                    />
                            </Form.Item>
                            <p style={{ fontSize: '14px', marginBottom: '-0px' }}>Exam Type</p>
                            <Form.Item name="clinicGlassesContactsBool">
                                <Checkbox 
                                    checked={clinicGlassesContactsBool}
                                    onChange={(e) => setClinicGlassesContactsBool(e.target.checked)}> 
                                    Routine (glasses and contacts)
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{marginTop: -24}} name="clinicMedicalBool">
                                <Checkbox 
                                    checked={clinicMedicalBool}
                                    onChange={(e) => setClinicMedicalBool(e.target.checked)}> Medical optometry
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{marginTop: -10}} name="clinicNotes" label="Add Notes:" >
                                <TextArea 
                                    rows={2} 
                                    placeholder="add notes" 
                                    style={{ marginTop: -2}} 
                                    value={clinicNotes}
                                    maxLength={600} 
                                    
                                    onChange={(e) => setClinicNotes(e.target.value)}
                                />
                            </Form.Item>
                        </Form>
                    </>
                </Modal>

            </div>
        </>

    );
}