
import React from "react";
import { Button, Modal, Popover} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

function WelcomeModal(props) {

    const { 
        // handleCancel,
        userData,
        isWelcomeModalOpen,
        OpenModal_JoinAnOrg,
        // OpenModal_CreateAnOrg,  // TODO: instead of opening this modal.. need to populate it and automatically create the org behind the scenes...
        OpenModal_CreateAClinic, 
        completeProfile
    } = props

    // const InfoPopover = () => {
    //     return (
    //         <Popover
    //             content={
    //                 <div style={{ maxWidth: '600px' }}>
    //                     <p><strong>About Organizations in OD Shift</strong></p>
    //                     <p>An Organization (Org) is the parent entity under which your clinics operate. Even if you manage just one clinic, it needs to be associated with an Org.</p>
    //                     <p><strong>For Clinic Owners:</strong></p>
    //                     <ul>
    //                         <li>If you own the clinic, create an Org to serve as the main entity for your business.</li>
    //                         <li>Once created, you can invite managers to join via the Org ID to access and manage your clinic’s calendars.</li>
    //                     </ul>
    //                     <p><strong>For Clinic Managers:</strong></p>
    //                     <ul>
    //                         <li>If you are managing a clinic but not the owner, you will need to join the Org created by the clinic owner to access the clinic’s calendar. Contact your company for the Org ID to join.</li>
    //                         <li>If you’re setting up an Org on the owner’s behalf and have the required details, click “Create an Org.”</li>
    //                     </ul>
    //                 </div>
    //             }
    //             placement="bottom"
    //         >
    //             <Button
    //                 type="text"
    //                 shape="circle"
    //                 icon={<InfoCircleOutlined style={{ color: '#1890ff', fontSize: '16px' }} />}
    //             />
    //         </Popover>
    //     );
    // };

    return(
        <>
            <Modal
                open={isWelcomeModalOpen}

                // do a check to make sure values are not undefined.
                title={ userData != undefined && userData.firstName != undefined ? "Welcome, " + userData.firstName + "!" : "Welcome to OD Shift!"}
                // onCancel={handleCancel}
                footer={ 
                    userData != undefined && userData.userType != undefined && userData.userType === "clinicTeamMember" ?
                    [
                    <Button key="joinOrg" className="secondary-button button-size-large" onClick={OpenModal_JoinAnOrg}>
                        Join a clinic
                    </Button>,

                    // When creating a clinic, check to see if this user is already associated to an org.. 
                    // if not, create and join them to an org automatically..
                    <Button key="createOrg" className="primary-button button-size-large" onClick={OpenModal_CreateAClinic}>
                        Create a clinic
                    </Button>
                    ]:
                    <Button key="completeProfile" className="primary-button button-size-large" onClick={completeProfile}>
                        Complete Profile
                    </Button>
                }
            >
            <p>
                {userData && userData.userType === "clinicTeamMember" ? (
                    <>
                        Is your clinic already on OD Shift? If so, join your team using your <b>Team ID</b>. If not, add your clinic. If you own or manage more than one clinic you can add the others later.
                        {/* <InfoPopover /> */}
                    </>
                ) : (
                    "Complete your profile to see open shifts."
                )}
            </p>
            </Modal>
        </>

    );
}

export default WelcomeModal











