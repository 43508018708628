import React, { useState, useRef, useEffect } from "react";
import "./PrivacyPolicy.css";
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from 'antd';
const { Title, Text, Paragraph } = Typography;

export default function PrivacyPolicy(props) {
  const navigate = useNavigate();

  const { 
    isMobile,
  } = props

  return (
    <div className="PrivacyPolicy">

      <div style={{
        width: isMobile ? '100%' : '80vw',
        paddingLeft: isMobile ? '20px' : '15vw',
        paddingRight: isMobile ? '20px' : '0px'
      }}>
        <h3 style={{ marginTop: isMobile ? '40px' : '60px', float: 'left', marginBottom: '40px' }}>OD Shift LLC - Privacy Policy</h3>
          <div className="modal-content">
            <Paragraph className="last-updated" style={{marginTop: '-30px'}}>Last updated: November 17, 2024</Paragraph>
            <br></br>
            <Paragraph>
              By using the OD Shift application, you agree to these Terms and Conditions ("Terms"). Please read them carefully.
            </Paragraph>

            <Title level={5} className="section-title">1. Acceptance of Terms</Title>
            <Paragraph>
              By downloading, installing, or using OD Shift, you agree to be bound by these Terms. If you do not agree to these Terms, you must not use the app.
            </Paragraph>

            <Title level={5} className="section-title">2. Use of the App</Title>
            <Paragraph>
              2.1. You agree to use the app only for lawful purposes and in accordance with these Terms.
            </Paragraph>
            <Paragraph>
              2.2. You are responsible for maintaining the confidentiality of your login credentials and are fully responsible for any activities that occur under your account.
            </Paragraph>

            <Title level={5} className="section-title">3. Personal Data Collection and Use</Title>
            <Paragraph>
              3.1. The app may collect personal data, including but not limited to your name, email address, clinic information or license information, and usage information such as device type and operating system, app features accessed, frequency and duration of use, interaction patterns with other users or features, and locations to provide and improve the service.
            </Paragraph>
            <Paragraph>
              3.2. Your data is stored securely and will not be sold/shared with any third parties.
            </Paragraph>
            <Paragraph>
              3.3. We use the data to:
              <ul>
                <li>Provide the app's services and features</li>
                <li>Improve and optimize the app</li>
                <li>Respond to your requests or questions</li>
              </ul>
            </Paragraph>
            <Paragraph>
              3.4. You have the right to access, modify your personal data on the app. You have the right to delete your personal data by contacting us at info.odshift@gmail.com.
            </Paragraph>

            <Title level={5} className="section-title">4. User Content</Title>
            <Paragraph>
              4.1. You may submit content to the app, such as messages, profile information, or other data. You retain ownership of any intellectual property rights in the content you submit.
            </Paragraph>
            <Paragraph>
              4.2. By submitting content to the app, you grant OD Shift LLC a worldwide, perpetual, irrevocable, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, and display your content solely for the purpose of providing and improving the app's services. This license ends when you delete your content or your account unless your content has been shared with others who have not deleted it or where retention is required for legal purposes.
            </Paragraph>
            <Paragraph>
              4.3. You represent and warrant that you own or have the necessary rights to submit the content and grant the license described in 4.2.
            </Paragraph>
            <Paragraph>
              4.4. We reserve the right to remove any content that violates these Terms or is deemed inappropriate, at our sole discretion.
            </Paragraph>

            <Title level={5} className="section-title">5. Intellectual Property</Title>
            <Paragraph>
              5.1. All rights, titles, and interests in the app and its content (excluding user-submitted content), including but not limited to software, text, images, and logos, are owned by OD Shift or its licensors.
            </Paragraph>
            <Paragraph>
              5.2. You may not copy, modify, distribute, or create derivative works from the app without our express written consent.
            </Paragraph>

            <Title level={5} className="section-title">6. Limitation of Liability</Title>
            <Paragraph>
              6.1. To the fullest extent permitted by law, OD Shift LLC, its affiliates, officers, employees, agents, partners, and licensors shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, revenue, goodwill, data, or other intangible losses, resulting from:
              <ul>
                <li>(a) your use or inability to use the app;</li>
                <li>(b) unauthorized access to or alteration of your transmissions or data;</li>
                <li>(c) errors, inaccuracies, or omissions in the app's services;</li>
                <li>(d) any bugs, viruses, or other harmful components transmitted through the app; or</li>
                <li>(e) any other matter relating to the app.</li>
              </ul>
            </Paragraph>
            <Paragraph>
              6.2. OD Shift is a platform for scheduling and connecting optometrists and clinics. We do not verify or guarantee the accuracy of information provided by users, nor are we responsible for the actions, decisions, or omissions of any users, including but not limited to optometrists or clinics.
            </Paragraph>
            <Paragraph>
              6.3. OD Shift LLC does not mediate disputes between users (optometrists and clinics). Any agreements, payments, or issues arising from shifts are solely between the parties involved.
            </Paragraph>
            <Paragraph>
              6.4. In jurisdictions that do not allow the exclusion or limitation of liability for certain damages, OD Shift LLC's liability shall be limited to the maximum extent permitted by law.
            </Paragraph>

            <Title level={5} className="section-title">7. Changes to the Terms</Title>
            <Paragraph>
              7.1. We reserve the right to update these Terms from time to time. Any changes will be posted within the app, and the "last updated" date will be revised.
            </Paragraph>
            <Paragraph>
              7.2. Your continued use of the app after changes are posted constitutes your acceptance of the updated Terms.
            </Paragraph>

            <Title level={5} className="section-title">8. Termination</Title>
            <Paragraph>
              8.1. We may suspend or terminate your access to the app at any time, for any reason, without notice.
            </Paragraph>
            <Paragraph>
              8.2. Upon termination, your right to use the app will cease immediately.
            </Paragraph>

            <Title level={5} className="section-title">9. Governing Law</Title>
            <Paragraph>
              These Terms are governed by and construed in accordance with the laws of the State of New York, without regard to its conflict of law principles. Any disputes arising from or relating to these Terms or the use of the app shall be subject to the exclusive jurisdiction of the state and federal courts located in New York City, New York.
            </Paragraph>

            <Title level={5} className="section-title">10. Contact Us</Title>
            <Paragraph>
            If you have any questions about these Terms, please contact us at <b>info.odshift@gmail.com</b>.
            </Paragraph>
          </div>


      </div>

      <br></br>


    </div>
  );
}